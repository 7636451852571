import { isNil } from 'lodash';

export const getProductRequirementsListBody = (id, searchItem, requirementId) => {
  const criterion = [
    {
      field: 'AssessmentId',
      operator: '=',
      value: id,
    },
  ];
  if (!isNil(searchItem)) {
    criterion.push({
      field: 'RequirementStatusId',
      operator: '=',
      value: searchItem,
    });
  }
  if (!isNil(requirementId)) {
    criterion.push({
      field: 'RequirementId',
      operator: '=',
      value: requirementId,
    });
  }

  return {
    intent: '',
    fields: [
      'RequirementId',
      'RequirementNumber',
      'RequirementName',
      'QuestionnaireTemplateId',
      'AssessmentQuestionnaireId',
      'AssessmentId',
      'AssessmentName',
      'AssessmentStatus',
      'RequirementStatus',
      'IsMet',
      'LastUpdated',
      'LastUpdatedRequirement',
      'AssessmentQuestionnaireRequirementId',
      'DynRequirementId',
      'RequirementMetUnMetStatus',
      'ExternalTicketId',
      'Adequacy',
      'IsSystem',
      'IsValidated',
      'SystemAdequacy',
      'AuditorAdequacy',
    ],
    criterion: criterion,
    sort: [
      {
        field: 'RequirementNumber',
        order: 'ASC',
      }
    ],
    page: 0,
    pageSize: 0
  }
}

export const getRequirementsCountBody = (id) => {
  const criterion = [
    {
      field: 'AssessmentId',
      operator: '=',
      value: id,
    },
  ];
  return {
    intent: '',
    fields: ['RequirementId', 'AssessmentId', 'AssessmentQuestionnaireId', 'RequirementStatus', 'TotalCountOfRequirementsAndResponse'],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getProductRequirementsQuestionsBody = (requirementId) => {
  return {
    Intent: '',
    Fields: [
      'AssessmentQuestionnaireId',
      'QuestionnaireTemplateQuestionId',
      'QuestionId',
      'QuestionResponseCategory',
      'QuestionResponseText',
      'QuestionResponseComment',
      'ResponseScore',
      'DisplayOrder',
      'IsRequired',
      'QuestionText',
      'AllowCommentInResponse',
      'QuestionType',
      'QuestionResponseType',
      'ResponseOptionList',
      'ChildQuestionList',
      'QuestionLogicRuleList',
      'UpdateTimestamp',
      'CountOfMandatoryQuestionsAndAnswers',
      'AllowFileUpload',
      'RequirementId',
      'QuestionPlainText',
    ],
    Criterion: [
      {
        Field: 'RequirementId',
        Operator: '=',
        Value: requirementId,
      },
      {
        Field: 'ParentQuestionId',
        Operator: '=',
        Value: '0',
      },
    ],
    Sort: [
      {
        Field: 'DisplayOrder',
        Order: 'ASC',
      },
    ],
    Page: 0,
    PageSize: 0,
  };
};
