import axios from 'axios';
import { isNil } from 'lodash';
import { REVIEW_SUMMARY_TAB_OPTIONS } from 'pages/Products/VendorCoPilot/OrganizationalRequirements/ReviewSummary/constants';
import {
  ASSESSMENT_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE,
  DEFAULT_PAGE_NO,
  ORGANISATIONAL_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE,
  PRODUCT_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE,
  STARTED_VENDOR_PROFILE_ASSESSMENT,
  VENDOR_COPILOT_PAGE_TYPE,
} from 'pages/Products/VendorCoPilot/constants';
import { getErrorObject } from 'utils/apiUtils';
import {
  initialiseCopilotProcessBody,
  getOrganisationalSecurityEvidenceRequirementListBody,
  getProductSecurityEvidenceRequirementListBody,
  getProductSpecificRequirementProductListBody,
  copilotAssessmentDetailsSearchBody,
  updateOrganisationalFileDescriptionBody,
  updateCopilotPageBody,
  getProductSpecificRequirementSummaryQuestionListBody,
  searchOrganisationalReviewSummaryQuestionsBody,
  searchOrganisationalReviewSummaryRequirementsBody,
  updateProductFileDescriptionBody,
  getSearchVendorCopilotImages,
  searchProductReviewSummaryRequirementsBody,
  searchProductReviewSummaryQuestionsBody,
  getSearchCopilotDru,
  updateCopilotStatusBody,
  searchGeneratedResponseStatusBody,
} from 'utils/vendorCopilotUtils';
import { setErrors } from './errorHandler';
import { searchAssessmentSecurityEvidenceRequirementList } from './assessmentCoPilot';
import { GENERATE_COPILOT_RESPONSE_TIMEOUT } from 'constants/constants';
import { ASSESSMENT_STATUS } from 'pages/CreateCompanionAssessment/constant';
import { toast } from 'react-toastify';
import { retryFunc } from 'utils/retryFunc';

export const SEARCH_COPILOT_PAGE_DETAILS_LOADING = '@vendorCopilot/SEARCH_COPILOT_PAGE_DETAILS_LOADING';
export const SEARCH_COPILOT_PAGE_DETAILS_SUCCESS = '@vendorCopilot/SEARCH_COPILOT_PAGE_DETAILS_SUCCESS';
export const SEARCH_COPILOT_PAGE_DETAILS_FAILURE = '@vendorCopilot/SEARCH_COPILOT_PAGE_DETAILS_FAILURE';

export const searchCopilotPageDetails = () => async (dispatch) => {
  dispatch({ type: SEARCH_COPILOT_PAGE_DETAILS_LOADING });
  try {
    const response = await axios.get(`/assessment/vendor/profile/setup/currentpage`);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_COPILOT_PAGE_DETAILS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_COPILOT_PAGE_DETAILS_SUCCESS,
        payload: response.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_COPILOT_PAGE_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_COPILOT_PAGE_DETAILS_LOADING = '@vendorCopilot/UPDATE_COPILOT_PAGE_DETAILS_LOADING';
export const UPDATE_COPILOT_PAGE_DETAILS_SUCCESS = '@vendorCopilot/UPDATE_COPILOT_PAGE_DETAILS_SUCCESS';
export const UPDATE_COPILOT_PAGE_DETAILS_FAILURE = '@vendorCopilot/UPDATE_COPILOT_PAGE_DETAILS_FAILURE';

export const updateCopilotPageDetails =
  (page = DEFAULT_PAGE_NO, callback) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_COPILOT_PAGE_DETAILS_LOADING });
    try {
      const body = updateCopilotPageBody(page);
      const response = await axios.post(`/assessment/vendor/profile/setup/currentpage/action/update`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: UPDATE_COPILOT_PAGE_DETAILS_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: UPDATE_COPILOT_PAGE_DETAILS_SUCCESS,
          payload: response.data || [],
        });
        dispatch(searchCopilotPageDetails());
        if(callback){
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: UPDATE_COPILOT_PAGE_DETAILS_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_COPILOT_ASSESSMENT_DETAILS_LOADING = '@vendorCopilot/SEARCH_COPILOT_ASSESSMENT_DETAILS_LOADING';
export const SEARCH_COPILOT_ASSESSMENT_DETAILS_SUCCESS = '@vendorCopilot/SEARCH_COPILOT_ASSESSMENT_DETAILS_SUCCESS';
export const SEARCH_COPILOT_ASSESSMENT_DETAILS_FAILURE = '@vendorCopilot/SEARCH_COPILOT_ASSESSMENT_DETAILS_FAILURE';

export const searchCopilotAssessmentDetails = () => async (dispatch) => {
  dispatch({ type: SEARCH_COPILOT_ASSESSMENT_DETAILS_LOADING });
  try {
    const body = copilotAssessmentDetailsSearchBody();
    const response = await axios.post(`/assessment/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_COPILOT_ASSESSMENT_DETAILS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_COPILOT_ASSESSMENT_DETAILS_SUCCESS,
        payload: response.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_COPILOT_ASSESSMENT_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const INITIALISE_CORL_COPILOT_PROCESS_LOADING = '@vendorCopilot/INITIALISE_CORL_COPILOT_PROCESS_LOADING';
export const INITIALISE_CORL_COPILOT_PROCESS_SUCCESS = '@vendorCopilot/INITIALISE_CORL_COPILOT_PROCESS_SUCCESS';
export const INITIALISE_CORL_COPILOT_PROCESS_FAILURE = '@vendorCopilot/INITIALISE_CORL_COPILOT_PROCESS_FAILURE';

export const initialiseCopilotProcess = (pageType = VENDOR_COPILOT_PAGE_TYPE, callback) => async (dispatch) => {
  dispatch({ type: INITIALISE_CORL_COPILOT_PROCESS_LOADING });
  try {
    const body = initialiseCopilotProcessBody();
    const response = await axios.post(`/assessment`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: INITIALISE_CORL_COPILOT_PROCESS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: INITIALISE_CORL_COPILOT_PROCESS_SUCCESS,
        payload: response.data || [],
        pageType: pageType
      });
      if (pageType !== VENDOR_COPILOT_PAGE_TYPE) {
        dispatch(searchCopilotPageDetails());
      }
      //update assessment object used throughout the Companion flow after Assessment for Vendor is generated 
      dispatch(searchCopilotAssessmentDetails());
      if(callback) {
        callback()
      }
    }
  } catch (err) {
    dispatch({
      type: INITIALISE_CORL_COPILOT_PROCESS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const START_PRODUCT_ORG_LEVEL_LOADING = '@vendorCopilot/START_PRODUCT_ORG_LEVEL_LOADING';
export const START_PRODUCT_ORG_LEVEL_SUCCESS = '@vendorCopilot/START_PRODUCT_ORG_LEVEL_SUCCESS';
export const START_PRODUCT_ORG_LEVEL_FAILURE = '@vendorCopilot/START_PRODUCT_ORG_LEVEL_FAILURE';

export const startProductOrgLevelAssessment = (vendorProduct) => async (dispatch) => {
  dispatch({ type: START_PRODUCT_ORG_LEVEL_LOADING });
  try {
    const response = await axios.put(`/vendor/product/${vendorProduct.ProductId}`, {
      ...vendorProduct,
      IsStartedVendorProfileAssessment: STARTED_VENDOR_PROFILE_ASSESSMENT,
    });
    if (response?.data?.ErrorCount > 0) {
      dispatch({ type: START_PRODUCT_ORG_LEVEL_FAILURE });
    } else {
      dispatch({ type: START_PRODUCT_ORG_LEVEL_SUCCESS });
    }
  } catch (err) {
    dispatch({ type: START_PRODUCT_ORG_LEVEL_FAILURE });
  }
};

export const GET_PRODUCT_BY_ID_LOADING = '@vendorCopilot/GET_PRODUCT_BY_ID_LOADING';
export const GET_PRODUCT_BY_ID_SUCCESS = '@vendorCopilot/GET_PRODUCT_BY_ID_SUCCESS';
export const GET_PRODUCT_BY_ID_FAILURE = '@vendorCopilot/GET_PRODUCT_BY_ID_FAILURE';

export const getProductById = (productId) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_BY_ID_LOADING });
  try {
    const param = {
      intent: '',
      fields: [
        'VendorOrgId',
        'VendorOrgName',
        'VendorUrl',
        'ProductName',
        'ProductType',
        'ProductVendorRole',
        'ProductId',
        'ProductGroup',
        'ProductNameForVendor',
        'IsClearedProcessStarted',
        'ExternalTicketId',
        'CCDynVRASId',
        'CORLClearedStatus',
        'ProductVendorAssessment',
        'ProductCategory',
        'CountOfConfirmAssociatedClient',
        'CountOfPendingApprovalAssociatedClient',
        'ProductVendorId',
      ],
      criterion: [{ Field: 'ProductVendorId', Operator: '=', Value: productId }],
      sort: [],
      page: 0,
      pageSize: 0,
    };
    const response = await axios.post(`/vendor/product/search`, param);
    if (response?.data?.ErrorCount > 0) {
      dispatch({ type: GET_PRODUCT_BY_ID_FAILURE });
    } else {
      dispatch({ type: GET_PRODUCT_BY_ID_SUCCESS, payload: response?.data?.Data?.Rows[0] });
    }
  } catch (err) {
    dispatch({ type: GET_PRODUCT_BY_ID_FAILURE });
  }
};

export const SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING =
  '@vendorCopilot/SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING';
export const SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS =
  '@vendorCopilot/SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS';
export const SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE =
  '@vendorCopilot/SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE';

export const searchOrganisationalSecurityEvidenceRequirementList =
  (assessmentId = null) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING });
    try {
      const body = getOrganisationalSecurityEvidenceRequirementListBody(assessmentId);
      const response = await axios.post(`assessment/assessmentsetuptemplateevidencecategory/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS,
          payload: response.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING = '@vendorCopilot/SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING';
export const SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS = '@vendorCopilot/SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS';
export const SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE = '@vendorCopilot/SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE';

export const searchProductSecurityEvidenceRequirementList =
  (productVendorId = null) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING });
    try {
      const body = getProductSecurityEvidenceRequirementListBody(productVendorId);
      const response = await axios.post(`/assessment/assessmentsetuptemplateevidencecategory/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS,
          payload: response.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING =
  '@vendorCopilot/DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING';
export const DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS =
  '@vendorCopilot/DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS';
export const DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE =
  '@vendorCopilot/DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE';

export const deleteOrganisationalSecurityEvidenceRequirementFile = (assessmentQuestionnaireEvidenceCategoryEvidenceInstanceId, assessmentId) => async (dispatch) => {
  dispatch({ type: DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING });
  try {
    const response = await axios.delete(`/assessment/assessmentquestionnaireevidencecategoryevidenceinstance/${assessmentQuestionnaireEvidenceCategoryEvidenceInstanceId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS,
        payload: response.data || [],
      });
      dispatch(searchOrganisationalSecurityEvidenceRequirementList(assessmentId));
    }
  } catch (err) {
    dispatch({
      type: DELETE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING = '@vendorCopilot/DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING';
export const DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS = '@vendorCopilot/DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS';
export const DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE = '@vendorCopilot/DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE';

export const deleteProductSecurityEvidenceRequirementFile = (assessmentQuestionnaireEvidenceCategoryEvidenceInstanceId, productVendorId) => async (dispatch) => {
  dispatch({ type: DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING });
  try {
    const response = await axios.delete(`/assessment/assessmentquestionnaireevidencecategoryevidenceinstance/${assessmentQuestionnaireEvidenceCategoryEvidenceInstanceId}`);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS,
        payload: response.data || [],
      });
      dispatch(searchProductSecurityEvidenceRequirementList(productVendorId));
    }
  } catch (err) {
    dispatch({
      type: DELETE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING =
  '@vendorCopilot/UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING';
export const UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS =
  '@vendorCopilot/UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS';
export const UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE =
  '@vendorCopilot/UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE';

export const updateOrganisationalSecurityEvidenceRequirementFileDescription = (comment, evidenceId, assessmentId) => async (dispatch) => {
  dispatch({ type: UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING });
  try {
    const body = updateOrganisationalFileDescriptionBody(comment, evidenceId);
    const response = await axios.post(`/assessment/evidence/${evidenceId}/action/comment`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS,
        payload: response.data || [],
      });
      dispatch(searchOrganisationalSecurityEvidenceRequirementList(assessmentId));
    }
  } catch (err) {
    dispatch({
      type: UPDATE_ORGANISATIONAL_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING =
  '@vendorCopilot/UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING';
export const UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS =
  '@vendorCopilot/UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS';
export const UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE =
  '@vendorCopilot/UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE';

export const updateProductSecurityEvidenceRequirementFileDescription = (comment, evidenceId, productVendorId) => async (dispatch) => {
  dispatch({ type: UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING });
  try {
    const body = updateProductFileDescriptionBody(comment, evidenceId);
    const response = await axios.post(`/assessment/evidence/${evidenceId}/action/comment`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS,
        payload: response.data || [],
      });
      dispatch(searchProductSecurityEvidenceRequirementList(productVendorId));
    }
  } catch (err) {
    dispatch({
      type: UPDATE_PRODUCT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_LOADING = '@vendorCopilot/SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_LOADING';
export const SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_SUCCESS = '@vendorCopilot/SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_SUCCESS';
export const SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_FAILURE = '@vendorCopilot/SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_FAILURE';

export const searchProductSpecificRequirementProductList =
  (searchData = null, callback) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_LOADING });
    try {
      const body = getProductSpecificRequirementProductListBody(searchData);
      const response = await axios.post(`/vendor/product/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_SUCCESS,
          payload: response.data || [],
        });
        if (callback) {
          callback(response.data);
        }
      }
    } catch (err) {
      dispatch({
        type: SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_PRODUCT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SET_REVIEW_SUMMARY_TAB_VALUE = '@vendorCopilot/SET_REVIEW_SUMMARY_TAB_VALUE';

export const setReviewSummaryTabValue =
  (value = REVIEW_SUMMARY_TAB_OPTIONS.ALL) =>
  async (dispatch) => {
    dispatch({
      type: SET_REVIEW_SUMMARY_TAB_VALUE,
      payload: value,
    });
  };

export const SET_CURRENT_PAGE = '@vendorCopilot/SET_CURRENT_PAGE';

export const setCurrentPage =
  (payload = DEFAULT_PAGE_NO) =>
  async (dispatch) => {
    dispatch({
      type: SET_CURRENT_PAGE,
      payload,
    });
  };

export const SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_LOADING = '@vendorCopilot/SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_LOADING';
export const SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_SUCCESS = '@vendorCopilot/SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_SUCCESS';
export const SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_FAILURE = '@vendorCopilot/SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_FAILURE';

export const searchOrganisationalReviewSummaryQuestions = (assessmentId, callback) => async (dispatch) => {
  dispatch({ type: SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_LOADING });
  try {
    const body = searchOrganisationalReviewSummaryQuestionsBody();
    const response = await axios.post(`assessment/${assessmentId}/questionnaire/response/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_SUCCESS,
        payload: response.data || [],
      });
      if (callback) {
        callback(response?.data?.Data?.Rows);
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ORGANISATIONAL_REVIEW_SUMMARY_QUESTIONS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_LOADING = '@vendorCopilot/GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_LOADING';
export const GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_SUCCESS = '@vendorCopilot/GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_SUCCESS';
export const GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_FAILURE = '@vendorCopilot/GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_FAILURE';
export const TURN_OFF_LOADING_GENERATED_RESPONSES = '@vendorCopilot/TURN_OFF_LOADING_GENERATED_RESPONSES';

export const generateOrganisationalReviewSummaryRespose =
  (assessmentId = null, questionList = {}, targetAssessmentId = null, productVendorId = 0, callback, callbackFail, documentIdList = [], isSilent =false) =>
  async (dispatch) => {
    dispatch({ type: GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_LOADING });

    let url;
    let body;
    if (!isNil(targetAssessmentId)) {
      url = `assessment/${assessmentId}/action/generatedruwithcopilot`;
      body = {
        SourceAssessmentId: assessmentId,
        TargetAssessmentId: targetAssessmentId,
        DocumentIdList: documentIdList,
        QuestionList: questionList,
        ProductVendorId: productVendorId ?? 0,
      };
    }

    try {
      const response = await axios.post(url, JSON.stringify(body), {
        timeout: GENERATE_COPILOT_RESPONSE_TIMEOUT,
      });
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_FAILURE,
          payload: [],
        });
      } else {
        if (!isSilent) {
          dispatch({
            type: GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_SUCCESS,
            payload: response.data || [],
          });
        }
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      if (!isNil(err.response)) {
        dispatch({
          type: GENERATE_ORGANISATIONAL_REVIEW_SUMMARY_RESPONSES_FAILURE,
          payload: getErrorObject(err),
        });
      }
      callbackFail(err);
    }
  };

export const SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING = '@vendorCopilot/SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING';
export const SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS = '@vendorCopilot/SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS';
export const SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE = '@vendorCopilot/SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE';

export const searchOrganisationalReviewProgressStatus = (assessmentId, callback) => async (dispatch) => {
  dispatch({ type: SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING });
  try {
    let body = searchGeneratedResponseStatusBody(assessmentId);
    const response = await axios.post(`/assessment/${assessmentId}/generatedresponse/progressstatus/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE,
        payload: [],
      });
    } else {
      if (callback) {
        const totalCountOfAIGeneratedQuestion = response.data?.Data?.Rows[0]?.TotalCountOfAiGeneratedQuestion;
        const totalCountOfDRUGeneratedQuestion = response.data?.Data?.Rows[0]?.TotalCountOfDRUGeneratedQuestion;
        let isResponseGenerated = ( totalCountOfAIGeneratedQuestion > 0 || totalCountOfDRUGeneratedQuestion > 0 );
        callback(isResponseGenerated);
      }
      dispatch({
        type: SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS,
        payload: response.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ORGANISATIONAL_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_LOADING = '@vendorCopilot/SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_LOADING';
export const SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_SUCCESS = '@vendorCopilot/SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_SUCCESS';
export const SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_FAILURE = '@vendorCopilot/SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_FAILURE';

export const searchOrganisationalReviewRequirementList = (assessmentId, assessmentQuestionnaireId) => async (dispatch) => {
  dispatch({ type: SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_LOADING });
  try {
    const body = searchOrganisationalReviewSummaryRequirementsBody(assessmentId, assessmentQuestionnaireId);

    const response = await axios.post(`assessment/questionnaire/requirement/search`, body);

    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_SUCCESS,
        payload: response.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_LOADING =
  '@vendorCopilot/SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_LOADING';
export const SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_SUCCESS =
  '@vendorCopilot/SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_SUCCESS';
export const SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_FAILURE =
  '@vendorCopilot/SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_FAILURE';

export const searchProductSpecificRequirementSummaryQuestionList =
  (id = null, type = null, assessmentQuestionnaireId = null, renderLoading = true) =>
  async (dispatch) => {
    renderLoading && dispatch({
      type: SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_LOADING,
    });

    try {
      let body;
      if (isNil(type)) {
        body = getProductSpecificRequirementSummaryQuestionListBody(null, assessmentQuestionnaireId);
      } else {
        body = getProductSpecificRequirementSummaryQuestionListBody(type, assessmentQuestionnaireId);
      }
      const fetchResponse = () => axios.post(`/assessment/${id}/questionnaire/response/search`, body);
      const response = await retryFunc(fetchResponse, 3);

      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_FAILURE,
        });
      } else {
        dispatch({
          type: SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_SUCCESS,
          payload: response?.data?.Data?.Rows || [],
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_PRODUCT_SPECIFIC_REQUIREMENT_SUMMARY_QUESTION_LIST_FAILURE,
      });
    }
  };

// Upload Evidence Files

export const CO_PILOT_UPLOAD_EVIDENCE_LOADING = '@@vendorCopilot/CO_PILOT_UPLOAD_EVIDENCE_LOADING';
export const CO_PILOT_UPLOAD_EVIDENCE_SUCCESS = '@@vendorCopilot/CO_PILOT_UPLOAD_EVIDENCE_SUCCESS';
export const CO_PILOT_UPLOAD_EVIDENCE_FAILURE = '@@vendorCopilot/CO_PILOT_UPLOAD_EVIDENCE_FAILURE';

export const uploadCoPilotEvidenceFiles = (formData, assessmentId, type, assessmentQuestionnaireId) => async (dispatch) => {
  dispatch({
    type: CO_PILOT_UPLOAD_EVIDENCE_LOADING,
  });

  let tempFormData = {
    ...formData,
  };
  let attachEvidenceList = [];

  delete tempFormData['UploadedFile'];

  let body = new FormData();

  Object.keys(tempFormData).forEach((key) => {
    body.append(key, JSON.stringify(tempFormData[key]));
  });

  formData?.UploadedFile.forEach((item) => {
    if (!isNil(item?.EvidenceId)) {
      attachEvidenceList.push({ EvidenceId: item?.EvidenceId, FileName: item?.FileName });
    } else {
      body.append(`UploadedFileList`, item?.UploadedFile);
    }
  });

  if (attachEvidenceList?.length > 0) {
    body.append(`EvidenceList`, JSON.stringify(attachEvidenceList));
  }

  try {
    const response = await axios.post(`/assessment/assessmentquestionnaireevidencecategoryevidenceinstance`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: CO_PILOT_UPLOAD_EVIDENCE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: CO_PILOT_UPLOAD_EVIDENCE_SUCCESS,
        payload: response?.data?.Data,
      });
      if (type === ORGANISATIONAL_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE) {
        dispatch(searchOrganisationalSecurityEvidenceRequirementList(assessmentId));
      } else if (type === PRODUCT_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE) {
        dispatch(searchProductSecurityEvidenceRequirementList(formData?.ProductVendorId));
      } else if (type === ASSESSMENT_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE) {
        dispatch(searchAssessmentSecurityEvidenceRequirementList(assessmentQuestionnaireId));
      }
    }
  } catch (err) {
    dispatch({
      type: CO_PILOT_UPLOAD_EVIDENCE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_VENDOR_COPILOT_IMAGES_LOADING = '@vendorCopilot/SEARCH_VENDOR_COPILOT_IMAGES_LOADING';
export const SEARCH_VENDOR_COPILOT_IMAGES_SUCCESS = '@vendorCopilot/SEARCH_VENDOR_COPILOT_IMAGES_SUCCESS';
export const SEARCH_VENDOR_COPILOT_IMAGES_FAILURE = '@vendorCopilot/SEARCH_VENDOR_COPILOT_IMAGES_FAILURE';

export const searchVendorCopilotImages =
  (imageName = null) =>
  async (dispatch) => {
    dispatch({
      type: SEARCH_VENDOR_COPILOT_IMAGES_LOADING,
    });
    const body = getSearchVendorCopilotImages(imageName);
    try {
      const response = await axios.post('/vendor/copilot/image/action/geturl', body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_VENDOR_COPILOT_IMAGES_FAILURE,
        });
      } else {
        const payload = {};
        payload[imageName] = response?.data?.Data;

        dispatch({
          type: SEARCH_VENDOR_COPILOT_IMAGES_SUCCESS,
          payload: payload,
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_VENDOR_COPILOT_IMAGES_FAILURE,
      });
    }
  };

export const SET_SELECTED_PRODUCT = '@vendorCopilot/SET_SELECTED_PRODUCT';

export const setSelectedProduct =
  (value = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_SELECTED_PRODUCT,
      payload: value,
    });
  };

export const GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_LOADING = '@vendorCopilot/GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_LOADING';
export const GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_SUCCESS = '@vendorCopilot/GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_SUCCESS';
export const GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_FAILURE = '@vendorCopilot/GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_FAILURE';

export const generateProductReviewSummaryRespose =
  (assessmentId = null, questionList = {}, targetAssessmentId = null, productVendorId = 0, callback) =>
  async (dispatch) => {
    dispatch({ type: GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_LOADING });

    let url;
    let body;
    if (isNil(targetAssessmentId)) {
      url = `assessment/${assessmentId}/action/generateresponse`;
      body = {
        AssessmentId: assessmentId,
        QuestionList: questionList,
        ProductVendorId: productVendorId ?? 0,
      };
    } else {
      url = `assessment/${assessmentId}/action/generatedruwithcopilot`;
      body = {
        SourceAssessmentId: assessmentId,
        TargetAssessmentId: targetAssessmentId,
        QuestionList: questionList,
        ProductVendorId: productVendorId ?? 0,
      };
    }

    try {
      const response = await axios.post(url, JSON.stringify(body), {
        timeout: GENERATE_COPILOT_RESPONSE_TIMEOUT,
      });
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_SUCCESS,
          payload: response.data || [],
        });
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: GENERATE_PRODUCT_REVIEW_SUMMARY_RESPONSES_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING = '@vendorCopilot/SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING';
export const SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS = '@vendorCopilot/SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS';
export const SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE = '@vendorCopilot/SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE';

export const searchProductReviewProgressStatus = (assessmentId, callback) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING });
  try {
    let body = searchGeneratedResponseStatusBody(assessmentId);
    const response = await axios.post(`/assessment/${assessmentId}/generatedresponse/progressstatus/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE,
        payload: [],
      });
    } else {
      if (callback) {
        const totalCountOfAIGeneratedQuestion = response.data?.Data?.Rows[0]?.TotalCountOfAiGeneratedQuestion;
        const totalCountOfDRUGeneratedQuestion = response.data?.Data?.Rows[0]?.TotalCountOfDRUGeneratedQuestion;
        let isResponseGenerated = ( totalCountOfAIGeneratedQuestion > 0 || totalCountOfDRUGeneratedQuestion > 0 );
        callback(isResponseGenerated, response?.data);
      }
      dispatch({
        type: SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS,
        payload: response.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_LOADING = '@vendorCopilot/SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_LOADING';
export const SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_SUCCESS = '@vendorCopilot/SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_SUCCESS';
export const SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_FAILURE = '@vendorCopilot/SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_FAILURE';

export const searchProductReviewRequirementList = (assessmentId, assessmentQuestionnaireId, type, questionType) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_LOADING });
  try {
    const body = searchProductReviewSummaryRequirementsBody(assessmentId, assessmentQuestionnaireId);
    const response = await axios.post(`assessment/questionnaire/requirement/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_SUCCESS,
        payload: response.data || [],
        assessmentType: type,
        questionType: questionType
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_REVIEW_REQUIREMENT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_LOADING = '@vendorCopilot/SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_LOADING';
export const SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_SUCCESS = '@vendorCopilot/SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_SUCCESS';
export const SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_FAILURE = '@vendorCopilot/SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_FAILURE';

export const searchProductReviewSummaryQuestions = (assessmentId, callback) => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_LOADING });
  try {
    const body = searchProductReviewSummaryQuestionsBody();
    const response = await axios.post(`assessment/${assessmentId}/questionnaire/response/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_SUCCESS,
        payload: response.data || [],
      });
      if (callback) {
        callback(response?.data?.Data?.Rows);
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_REVIEW_SUMMARY_QUESTIONS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST = '@vendorCopilot/SET_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST';

export const setOrganisationalReviewRequirementList =
  (value = []) =>
  async (dispatch) => {
    dispatch({
      type: SET_ORGANISATIONAL_REVIEW_REQUIREMENT_LIST,
      payload: value,
    });
  };

export const SET_PRODUCT_REVIEW_REQUIREMENT_LIST = '@vendorCopilot/SET_PRODUCT_REVIEW_REQUIREMENT_LIST';

export const setProductReviewRequirementList =
  (value = []) =>
  async (dispatch) => {
    dispatch({
      type: SET_PRODUCT_REVIEW_REQUIREMENT_LIST,
      payload: value,
    });
  };

export const SET_ASSESSMENT_QUESTIONNAIRE_PAGE_ROUTE = '@vendorCopilot/SET_ASSESSMENT_QUESTIONNAIRE_PAGE_ROUTE';

export const setAssessmentQuestionnairePageRoute =
  (value = null) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_QUESTIONNAIRE_PAGE_ROUTE,
      payload: value,
    });
  };

export const SEARCH_COPILOT_DRU_LOADING = '@vendorCopilot/SEARCH_COPILOT_DRU_LOADING';
export const SEARCH_COPILOT_DRU_SUCCESS = '@vendorCopilot/SEARCH_COPILOT_DRU_SUCCESS';
export const SEARCH_COPILOT_DRU_FAILURE = '@vendorCopilot/SEARCH_COPILOT_DRU_FAILURE';

export const searchCopilotDru = (assessmentId, callback) => async (dispatch) => {
  const body = getSearchCopilotDru(assessmentId);
  dispatch({
    type: SEARCH_COPILOT_DRU_LOADING,
  });
  try {
    const res = await axios.post('/assessment/dru/search', body);
    if (res?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_COPILOT_DRU_FAILURE,
      });
      const errors = res?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_COPILOT_DRU_SUCCESS,
        payload: res?.data?.Data?.Rows,
      });
      if(callback) {
        callback(res?.data?.Data?.Rows);
      }
    }
  } catch (error) {
    dispatch({
      type: SEARCH_COPILOT_DRU_FAILURE,
    });
    if(callback) {
      callback([]);
    }
  }
};

export const UPDATE_COPILOT_STATUS_LOADING = '@vendorCopilot/UPDATE_COPILOT_STATUS_LOADING';
export const UPDATE_COPILOT_STATUS_SUCCESS = '@vendorCopilot/UPDATE_COPILOT_STATUS_SUCCESS';
export const UPDATE_COPILOT_STATUS_FAILURE = '@vendorCopilot/UPDATE_COPILOT_STATUS_FAILURE';

export const updateCopilotStatus =
  (assessmentId, assessmentQuestionnaireId, callback) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_COPILOT_STATUS_LOADING });
    try {
      const body = updateCopilotStatusBody(assessmentId, assessmentQuestionnaireId);
      const response = await axios.post(`/assessment/${assessmentId}/questionnaire/action/confirm`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: UPDATE_COPILOT_STATUS_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: UPDATE_COPILOT_STATUS_SUCCESS,
          payload: response.data || [],
        });
        if(callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: UPDATE_COPILOT_STATUS_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

  
export const updateAssessmentStatus = (assessmentId, callback) => async () => {
  try {
    const payload = {
      AssessmentId: assessmentId,
      NewAssessmentStatus: ASSESSMENT_STATUS.COMPLETED,
      OldAssessmentStatus: ASSESSMENT_STATUS.IN_PROGRESS,
    };
    const res = await axios.post(`/assessment/${assessmentId}/action/updatestatus`, payload);
    toast.success(res.data.Data?.message ?? 'Companion assessment status updated successfully.');
    callback?.();
  } catch (err) {
    toast.error(err.response?.data?.title ?? 'Failed to update companion assessment status.');
  }
};

export const updateAssessmentStatusAfterResetAnswers = (assessmentId, questionnaireQuestionResponseResetTypeId, callback) => async () => {
  try {
    const payload = {
      AssessmentId: assessmentId,
      NewAssessmentStatus: ASSESSMENT_STATUS.IN_PROGRESS,
      OldAssessmentStatus: ASSESSMENT_STATUS.IN_PROGRESS,
    };
    await axios.post(`/assessment/${assessmentId}/action/updatestatus`, payload);
    callback?.(questionnaireQuestionResponseResetTypeId);
  } catch (err) {
    toast.error(err.response?.data?.title ?? 'Failed to update companion assessment status.');
  }
};