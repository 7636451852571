import axios from 'axios';
import { getErrorObject } from '../utils/apiUtils';
import {
  getProductGroupListBody,
  getProductListBody,
  getVendorProductByIdBody,
  searchCorlClearedInfoBody,
  searchCorlClearedProductCountBody,
  searchVendorProductPreferencesBody,
  searchVendorContractListSearchBody,
  searchProductProfileWizardImageBody,
  getVendorProductAssessmentBody,
  getVendorProductDetailsRecommendationsBody,
  getVendorProductDetailsRecommendationsTotalBody,
  getVendorProductDetailsUpcomingMaintenanceBody,
  searchVendorProductBody,
  getCorlClearedAssessmentRequestBody,
  getCorlClearedAssessmentBody,
} from '../utils/vendorProductUtils';
import { setCustomErrors } from './customErrorHandler';
import { setErrors } from './errorHandler';
import { DEASSOCIATE_PRODUCTS_FROM_PRODUCTGROUP_ERROR_MESSAGE, DEFAULT_PRODUCT_INFORMATION_QUESTION_INDEX, DEFAULT_PRODUCT_PREFERENCE_QUESTION_INDEX, PRODUCT_ALREADY_EXISTS, PRODUCT_INFORMATION_WIZARD_QUESTION_TYPE, PRODUCT_ORG_PREFERENCE_WIZARD_QUESTION_TYPE, PRODUCT_PROFILE_WIZARD_TAB_OPTIONS, VENDORS_PRODUCTS_TAB_OPTIONS } from 'pages/Products/constants';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { PREFERENCE_DENY } from 'pages/ProductDetails/constants';
import { isNil } from 'lodash';
import { ANONYMS_URL_PATH, CORL_CLEARED_ASSESSMENT_TIMEOUT } from 'constants/constants';

export const SEARCH_PRODUCT_GROUP_LIST_LOADING = '@@vendorProductGroup/SEARCH_PRODUCT_GROUP_LIST_LOADING';
export const SEARCH_PRODUCT_GROUP_LIST_SUCCESS = '@@vendorProductGroup/SEARCH_PRODUCT_GROUP_LIST_SUCCESS';
export const SEARCH_PRODUCT_GROUP_LIST_FAILURE = '@@vendorProductGroup/SEARCH_PRODUCT_GROUP_LIST_FAILURE';

export const searchProductGroupList = () => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_GROUP_LIST_LOADING });
  let body = getProductGroupListBody();
  try {
    const response = await axios.post('vendor/productgroup/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_PRODUCT_GROUP_LIST_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_PRODUCT_GROUP_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_GROUP_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_PRODUCT_GROUP_TO_LIST_LOADING = '@@vendorProductGroup/POST_PRODUCT_GROUP_TO_LIST_LOADING';
export const POST_PRODUCT_GROUP_TO_LIST_SUCCESS = '@@vendorProductGroup/POST_PRODUCT_GROUP_TO_LIST_SUCCESS';
export const POST_PRODUCT_GROUP_TO_LIST_FAILURE = '@@vendorProductGroup/POST_PRODUCT_GROUP_TO_LIST_FAILURE';

export const addProductGrouptoList = (formData, callback) => async (dispatch) => {
  dispatch({
    type: POST_PRODUCT_GROUP_TO_LIST_LOADING,
  });
  const body = JSON.stringify({ ...formData, active: true });

  try {
    const response = await axios.post('vendor/productgroup', body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_PRODUCT_GROUP_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: POST_PRODUCT_GROUP_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchProductGroupList());
      if (callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: POST_PRODUCT_GROUP_TO_LIST_FAILURE,
    });
  }
};

export const PUT_PRODUCT_GROUP_TO_LIST_LOADING = '@@vendorProductGroup/PUT_PRODUCT_GROUP_TO_LIST_LOADING';
export const PUT_PRODUCT_GROUP_TO_LIST_SUCCESS = '@@vendorProductGroup/PUT_PRODUCT_GROUP_TO_LIST_SUCCESS';
export const PUT_PRODUCT_GROUP_TO_LIST_FAILURE = '@@vendorProductGroup/PUT_PRODUCT_GROUP_TO_LIST_FAILURE';

export const updateProductGroupinList = (formData, callback) => async (dispatch) => {
  dispatch({
    type: PUT_PRODUCT_GROUP_TO_LIST_LOADING,
  });
  const body = JSON.stringify(formData);
  try {
    const response = await axios.put(`vendor/productgroup/${formData?.ProductGroupId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_PRODUCT_GROUP_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_PRODUCT_GROUP_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchProductGroupList());
      if (callback) {
        callback();
      }
    }
  } catch {
    dispatch({
      type: PUT_PRODUCT_GROUP_TO_LIST_FAILURE,
    });
  }
};

export const DELETE_PRODUCT_GROUP_FROM_LIST_LOADING = '@@vendorProductGroup/DELETE_PRODUCT_GROUP_FROM_LIST_LOADING';
export const DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS = '@@vendorProductGroup/DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS';
export const DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE = '@@vendorProductGroup/DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE';

export const deleteProductGroupinList =
  (productGroupId, forceDelete = false, callback) =>
    async (dispatch) => {
      dispatch({
        type: DELETE_PRODUCT_GROUP_FROM_LIST_LOADING,
      });
      let url = `/vendor/productgroup/${productGroupId}`;
      if (forceDelete) {
        url = `${url}?forceDelete=true`;
      }

      try {
        const response = await axios.delete(url);
        if (response?.data?.ErrorCount > 0) {
          const errors = response?.data?.Errors[0]?.Message?.toLowerCase()?.includes(DEASSOCIATE_PRODUCTS_FROM_PRODUCTGROUP_ERROR_MESSAGE);
          dispatch({
            type: DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE,
            payload: errors,
          });
        } else {
          dispatch({
            type: DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS,
            payload: response?.data?.Data,
          });
          dispatch(searchProductGroupList());
          if (callback) {
            callback();
          }
        }
      } catch {
        dispatch({
          type: DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE,
        });
      }
    };

export const SEARCH_PRODUCT_LIST_LOADING = '@@vendorProduct/SEARCH_PRODUCT_LIST_LOADING';
export const SEARCH_PRODUCT_LIST_SUCCESS = '@@vendorProduct/SEARCH_PRODUCT_LIST_SUCCESS';
export const SEARCH_PRODUCT_LIST_FAILURE = '@@vendorProduct/SEARCH_PRODUCT_LIST_FAILURE';

export const searchProductList = () => async (dispatch) => {
  dispatch({ type: SEARCH_PRODUCT_LIST_LOADING });
  let body = getProductListBody();
  try {
    const response = await axios.post('vendor/product/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_PRODUCT_LIST_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_PRODUCT_LIST_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_PRODUCT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_VENDOR_PRODUCT_BY_ID_LOADING = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_BY_ID_LOADING';
export const SEARCH_VENDOR_PRODUCT_BY_ID_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_BY_ID_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_BY_ID_FAILURE = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_BY_ID_FAILURE';

export const searchVendorProductById = (vendorProductId, isAnonyms) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_BY_ID_LOADING });
  let body = getVendorProductByIdBody(vendorProductId);
  const anonymsPath = isAnonyms ? ANONYMS_URL_PATH : '';
  try {
    const response = await axios.post(`vendor/product${anonymsPath}/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_BY_ID_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_BY_ID_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_BY_ID_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_PRODUCT_TO_LIST_LOADING = '@@vendorProduct/POST_PRODUCT_TO_LIST_LOADING';
export const POST_PRODUCT_TO_LIST_SUCCESS = '@@vendorProduct/POST_PRODUCT_TO_LIST_SUCCESS';
export const POST_PRODUCT_TO_LIST_FAILURE = '@@vendorProduct/POST_PRODUCT_TO_LIST_FAILURE';

export const addProductstoList = (formData) => async (dispatch) => {
  dispatch({
    type: POST_PRODUCT_TO_LIST_LOADING,
  });
  const body = JSON.stringify({ ...formData, active: true });
  try {
    const response = await axios.post('vendor/product', body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_PRODUCT_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: POST_PRODUCT_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchProductList());
    }
  } catch {
    dispatch({
      type: POST_PRODUCT_TO_LIST_FAILURE,
    });
  }
};

export const PUT_PRODUCT_TO_LIST_LOADING = '@@vendorProduct/PUT_PRODUCT_TO_LIST_LOADING';
export const PUT_PRODUCT_TO_LIST_SUCCESS = '@@vendorProduct/PUT_PRODUCT_TO_LIST_SUCCESS';
export const PUT_PRODUCT_TO_LIST_FAILURE = '@@vendorProduct/PUT_PRODUCT_TO_LIST_FAILURE';

export const updateProductsinList = (formData, productId) => async (dispatch) => {
  dispatch({
    type: PUT_PRODUCT_TO_LIST_LOADING,
  });

  const body = JSON.stringify(formData);
  try {
    const response = await axios.put(`vendor/product/${productId}`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_PRODUCT_TO_LIST_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_PRODUCT_TO_LIST_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchProductList());
      dispatch(searchProductGroupList());
    }
  } catch {
    dispatch({
      type: PUT_PRODUCT_TO_LIST_FAILURE,
    });
  }
};

export const DELETE_PRODUCT_FROM_LIST_LOADING = '@@vendorProduct/DELETE_PRODUCT_FROM_LIST_LOADING';
export const DELETE_PRODUCT_FROM_LIST_SUCCESS = '@@vendorProduct/DELETE_PRODUCT_FROM_LIST_SUCCESS';
export const DELETE_PRODUCT_FROM_LIST_FAILURE = '@@vendorProduct/DELETE_PRODUCT_FROM_LIST_FAILURE';

export const deleteProductsinList =
  (productVendorId, forcedState = false) =>
    async (dispatch) => {
      dispatch({
        type: DELETE_PRODUCT_FROM_LIST_LOADING,
      });
      let url = `/vendor/product/${productVendorId}`;
      if (forcedState) {
        url = `${url}?forceDelete=true`;
      }
      try {
        const response = await axios.delete(url);
        if (response?.data?.ErrorCount > 0) {
          dispatch(setProductDeleteErrors(response?.data?.Errors));
          dispatch(setCustomErrors(response?.data?.Errors));
          dispatch({
            type: DELETE_PRODUCT_FROM_LIST_FAILURE,
          });
        } else {
          dispatch({
            type: DELETE_PRODUCT_FROM_LIST_SUCCESS,
            payload: response?.data?.Data,
          });
          dispatch(searchProductList());
        }
      } catch {
        dispatch({
          type: DELETE_PRODUCT_FROM_LIST_FAILURE,
        });
      }
    };

export const SET_VENDORS_PRODUCTS_TAB_VALUE = '@products/SET_VENDORS_PRODUCTS_TAB_VALUE';

export const setVendorProductsTabValue =
  (value = VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS) =>
    async (dispatch) => {
      dispatch({
        type: SET_VENDORS_PRODUCTS_TAB_VALUE,
        payload: value,
      });
    };

export const SET_PRODUCT_DELETE_ERRORS = '@products/SET_PRODUCT_DELETE_ERRORS';

export const setProductDeleteErrors = (value) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_DELETE_ERRORS,
    payload: value,
  });
};

export const SET_PRODUCTS_GROUP_FORCE_DELETE = '@products/SET_PRODUCTS_GROUP_FORCE_DELETE';

export const setProductsGroupForceDelete = (value) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCTS_GROUP_FORCE_DELETE,
    payload: value,
  });
};

export const COMMENT_ASSESSMENT_RESULT_LOADING = '@@vendorProduct/COMMENT_ASSESSMENT_RESULT_LOADING';
export const COMMENT_ASSESSMENT_RESULT_SUCCESS = '@@vendorProduct/COMMENT_ASSESSMENT_RESULT_SUCCESS';
export const COMMENT_ASSESSMENT_RESULT_FAILURE = '@@vendorProduct/COMMENT_ASSESSMENT_RESULT_FAILURE';

export const commentAssessmentResult = (formData) => async (dispatch) => {
  dispatch({
    type: COMMENT_ASSESSMENT_RESULT_LOADING,
  });
  const body = JSON.stringify(formData);
  try {
    const response = await axios.post(`/assessment/${formData?.AssessmentId}/action/comment`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: COMMENT_ASSESSMENT_RESULT_FAILURE,
      });
    } else {
      dispatch({
        type: COMMENT_ASSESSMENT_RESULT_SUCCESS,
        payload: response?.data?.Data,
        acceptStatusId: formData?.AcceptStatusId,
      });
      dispatch(setAcceptOrDeny(formData?.AcceptStatusId === PREFERENCE_DENY ? 'deny' : 'accept'));
      dispatch(searchVendorProductAssessment(formData?.ProductVendorId));
    }
  } catch {
    dispatch({
      type: COMMENT_ASSESSMENT_RESULT_FAILURE,
    });
  }
};

export const SET_CC_CONGRATULATE_MODAL = '@products/SET_CC_CONGRATULATE_MODAL';

export const setCongratulateModal = (value) => async (dispatch) => {
  dispatch({
    type: SET_CC_CONGRATULATE_MODAL,
    payload: value,
  });
};

export const SET_CC_ACCEPT_OR_DENY = '@products/SET_CC_ACCEPT_OR_DENY';

export const setAcceptOrDeny = (value) => async (dispatch) => {
  dispatch({
    type: SET_CC_ACCEPT_OR_DENY,
    payload: value,
  });
};

export const SEARCH_VENDOR_CONTRACT_LIST_LOADING = '@@vendorProduct/SEARCH_VENDOR_CONTRACT_LIST_LOADING';
export const SEARCH_VENDOR_CONTRACT_LIST_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_CONTRACT_LIST_SUCCESS';
export const SEARCH_VENDOR_CONTRACT_LIST_FAILURE = '@@vendorProduct/SEARCH_VENDOR_CONTRACT_LIST_FAILURE';

export const searchVendorContractList = (callback) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_CONTRACT_LIST_LOADING });
  try {
    const body = searchVendorContractListSearchBody();
    const response = await axios.post(`/vendor/current/contract/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: SEARCH_VENDOR_CONTRACT_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_CONTRACT_LIST_SUCCESS,
        payload: response?.data,
      });
      if (callback) {
        callback(response?.data);
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_CONTRACT_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_CORL_CLEARED_PRODUCT_COUNT_LOADING = '@@vendorProduct/SEARCH_CORL_CLEARED_PRODUCT_COUNT_LOADING';
export const SEARCH_CORL_CLEARED_PRODUCT_COUNT_SUCCESS = '@@vendorProduct/SEARCH_CORL_CLEARED_PRODUCT_COUNT_SUCCESS';
export const SEARCH_CORL_CLEARED_PRODUCT_COUNT_FAILURE = '@@vendorProduct/SEARCH_CORL_CLEARED_PRODUCT_COUNT_FAILURE';

export const searchCorlClearedProductCount = () => async (dispatch) => {
  dispatch({ type: SEARCH_CORL_CLEARED_PRODUCT_COUNT_LOADING });

  let body = searchCorlClearedProductCountBody();
  try {
    const response = await axios.post(`/vendor/product/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_CORL_CLEARED_PRODUCT_COUNT_FAILURE,
        payload: {},
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: SEARCH_CORL_CLEARED_PRODUCT_COUNT_SUCCESS,
        payload: response?.data?.Data?.Rows[0],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_CORL_CLEARED_PRODUCT_COUNT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_CORL_CLEARED_INFO_LOADING = '@@vendorProduct/SEARCH_CORL_CLEARED_INFO_LOADING';
export const SEARCH_CORL_CLEARED_INFO_SUCCESS = '@@vendorProduct/SEARCH_CORL_CLEARED_INFO_SUCCESS';
export const SEARCH_CORL_CLEARED_INFO_FAILURE = '@@vendorProduct/SEARCH_CORL_CLEARED_INFO_FAILURE';

export const searchCorlClearedInfo = () => async (dispatch) => {
  dispatch({ type: SEARCH_CORL_CLEARED_INFO_LOADING });

  let body = searchCorlClearedInfoBody();
  try {
    const response = await axios.post(`/vendor/product/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_CORL_CLEARED_INFO_FAILURE,
        payload: {},
      });
      dispatch(setErrors(response?.data?.Errors));
    } else {
      dispatch({
        type: SEARCH_CORL_CLEARED_INFO_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_CORL_CLEARED_INFO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_LOADING = '@@vendorProduct/CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_LOADING';
export const CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_SUCCESS = '@@vendorProduct/CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_SUCCESS';
export const CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_FAILURE = '@@vendorProduct/CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_FAILURE';

export const createProductVendorClearedProcessTicket = (formData, callback = undefined) => async (dispatch) => {
  dispatch({
    type: CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_LOADING,
  });
  const body = JSON.stringify(formData);
  try {
    const response = await axios.post(`/vendor/product/corlclearedprocess/action/ticket`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_FAILURE,
      });
    } else {
      dispatch({
        type: CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_SUCCESS,
        payload: response?.data?.Data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_VENDOR_PRODUCT_PREFERENCES_LOADING = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_PREFERENCES_LOADING';
export const SEARCH_VENDOR_PRODUCT_PREFERENCES_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_PREFERENCES_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_PREFERENCES_FAILURE = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_PREFERENCES_FAILURE';

export const searchVendorProductPreferences = (currentIndex = null, isReview) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_PREFERENCES_LOADING });
  try {
    const body = searchVendorProductPreferencesBody();
    const response = await axios.post(`/vendor/productpreferences/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_PREFERENCES_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_PREFERENCES_SUCCESS,
        payload: response?.data.Data?.Rows,
        currentIndex: currentIndex,
        isReview: isReview
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_PREFERENCES_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_LOADING = '@@vendorProduct/POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_LOADING';
export const POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_SUCCESS = '@@vendorProduct/POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_SUCCESS';
export const POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_FAILURE = '@@vendorProduct/POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_FAILURE';

export const saveVendorProductPreference = (data, currentIndex, isReview) => async (dispatch) => {
  dispatch({ type: POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_LOADING });
  try {
    const body = JSON.stringify(data);
    const response = await axios.post(`/vendor/productpreferenceresponse`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchVendorProductPreferences(currentIndex, isReview));
    }
  } catch (err) {
    dispatch({
      type: POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_PRODUCT_PREFERENCE_QUESTION_INDEX = '@@vendorProduct/SET_PRODUCT_PREFERENCE_QUESTION_INDEX';

export const setProductPreferenceQuestionIndex = (index = DEFAULT_PRODUCT_PREFERENCE_QUESTION_INDEX) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_PREFERENCE_QUESTION_INDEX,
    payload: index,
  })
}

export const SET_PRODUCT_PREFERENCE_QUESTION_LIST = '@@vendorProduct/SET_PRODUCT_PREFERENCE_QUESTION_LIST';

export const setProductPreferenceQuestionList = (list = []) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_PREFERENCE_QUESTION_LIST,
    payload: list,
  })
}

export const GET_PRODUCT_PREFERENCE_IMAGE_URL_LOADING = '@@vendorProduct/GET_PRODUCT_PREFERENCE_IMAGE_URL_LOADING';
export const GET_PRODUCT_PREFERENCE_IMAGE_URL_SUCCESS = '@@vendorProduct/GET_PRODUCT_PREFERENCE_IMAGE_URL_SUCCESS';
export const GET_PRODUCT_PREFERENCE_IMAGE_URL_FAILURE = '@@vendorProduct/PRODUCT_PREFERENCE_IMAGE_URL_FAILURE';

export const getProductPreferenceImageUrl = (productPreferenceQuestionId) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_PREFERENCE_IMAGE_URL_LOADING });

  try {
    const body = searchProductProfileWizardImageBody();
    const res = await axios.post(`/vendor/productpreferences/${productPreferenceQuestionId}/image/action/geturl`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter(
        (it) => it.Message !== NO_DATA_FOUND
      );
      dispatch(setErrors(errors));
      dispatch({
        type: GET_PRODUCT_PREFERENCE_IMAGE_URL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_PRODUCT_PREFERENCE_IMAGE_URL_SUCCESS,
        payload: res?.data,
        ProductPreferenceQuestionId: productPreferenceQuestionId
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_PREFERENCE_IMAGE_URL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_LOADING = '@@vendorProduct/POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_LOADING';
export const POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_SUCCESS = '@@vendorProduct/POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_SUCCESS';
export const POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_FAILURE = '@@vendorProduct/POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_FAILURE';

export const saveVendorProductPreferences = (responseList, callback) => async (dispatch) => {
  dispatch({ type: POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_LOADING });
  try {
    const body = JSON.stringify(responseList);
    const response = await axios.post(`/vendor/productpreferenceresponse/action/bulk`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_SUCCESS,
        payload: response?.data,
      });
      if (callback) {
        if (response?.data?.Data?.length > 0) {
          callback(response?.data?.Data[0]);
        }
      }
    }
  } catch (err) {
    dispatch({
      type: POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_VENDOR_PRODUCT_ASSESSMENT_LOADING = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_ASSESSMENT_LOADING';
export const SEARCH_VENDOR_PRODUCT_ASSESSMENT_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_ASSESSMENT_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_ASSESSMENT_FAILURE = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_ASSESSMENT_FAILURE';

export const searchVendorProductAssessment = (vendorProductId, isAnonyms) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_ASSESSMENT_LOADING });
  let body = getVendorProductAssessmentBody(vendorProductId);
  const anonymsPath = isAnonyms ? ANONYMS_URL_PATH : '';
  try {
    const response = await axios.post(`vendor/assessment${anonymsPath}/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_ASSESSMENT_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_ASSESSMENT_SUCCESS,
        payload: response?.data.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_ASSESSMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const PUT_ASESSMENT_SHARED_STATUS_LOADING = '@@vendorProductGroup/PUT_ASESSMENT_SHARED_STATUS_LOADING';
export const PUT_ASESSMENT_SHARED_STATUS_SUCCESS = '@@vendorProductGroup/PUT_ASESSMENT_SHARED_STATUS_SUCCESS';
export const PUT_ASESSMENT_SHARED_STATUS_FAILURE = '@@vendorProductGroup/PUT_ASESSMENT_SHARED_STATUS_FAILURE';

export const updateAssessmentSharedStatus = (formData) => async (dispatch) => {
  dispatch({
    type: PUT_ASESSMENT_SHARED_STATUS_LOADING,
  });
  let tempFormData = {
    AssessmentId: formData?.ChildAssessmentId ? formData?.ChildAssessmentId : 0,
    ParentAssessmentId: formData?.ParentAssessmentId ? formData?.ParentAssessmentId : 0,
    ReleasedToClientId: formData?.ReleasedToClient,
    AssessmentRequestId: formData?.AssessmentRequestId,
  };

  const body = JSON.stringify(tempFormData);
  try {
    const response = await axios.post(`assessment/${formData?.AssessmentId}/action/releasedtoclient`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: PUT_ASESSMENT_SHARED_STATUS_FAILURE,
      });
    } else {
      dispatch({
        type: PUT_ASESSMENT_SHARED_STATUS_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchVendorProductAssessment(formData?.ProductVendorId));
    }
  } catch {
    dispatch({
      type: PUT_ASESSMENT_SHARED_STATUS_FAILURE,
    });
  }
};

export const SET_EXPANDED_PRODUCT_RECOMMENDATION_ID = '@products/SET_EXPANDED_PRODUCT_RECOMMENDATION_ID';

export const setExpandedProductRecommendationId = (value) => async (dispatch) => {
  dispatch({
    type: SET_EXPANDED_PRODUCT_RECOMMENDATION_ID,
    payload: value,
  });
};

export const SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_LOADING = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_LOADING';
export const SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_FAILURE = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_FAILURE';

export const searchVendorProductDetailsRecommendations = (assessmentId, recommedationStatusId) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_LOADING });
  let body = getVendorProductDetailsRecommendationsBody(assessmentId, recommedationStatusId);
  try {
    const response = await axios.post('/assessment/productvendor/recommendations/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_SUCCESS,
        payload: response?.data.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_LOADING = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_LOADING';
export const SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_FAILURE = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_FAILURE';

export const searchVendorProductDetailsRecommendationsTotal = (assessmentId) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_LOADING });
  let body = getVendorProductDetailsRecommendationsTotalBody(assessmentId);
  try {
    const response = await axios.post('/assessment/productvendor/recommendations/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_SUCCESS,
        payload: response?.data.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_LOADING = '@@vendorProduct/POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_LOADING';
export const POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_SUCCESS = '@@vendorProduct/POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_SUCCESS';
export const POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_FAILURE = '@@vendorProduct/POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_FAILURE';

export const saveVendorProductRecommendations = (selectedRecommendation, statusId, productVendorId, pendingTab) => async (dispatch) => {
  dispatch({ type: POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_LOADING });
  try {
    const body = JSON.stringify({
      AssessmentQuestionnaireRequirementId: selectedRecommendation?.AssessmentQuestionnaireRequirementId,
      RecommendationStatusId: statusId,
      AssessmentId: selectedRecommendation.AssessmentId,
      AssessmentQuestionnaireId: selectedRecommendation?.AssessmentQuestionnaireId,
    });
    const response = await axios.post(`/assessment/productvendor/questionnaire/requirement/${selectedRecommendation?.AssessmentQuestionnaireRequirementId}/action/updaterecommendationstatus`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchVendorProductDetailsRecommendationsTotal(selectedRecommendation?.AssessmentId));
      dispatch(searchVendorProductDetailsRecommendations(selectedRecommendation?.AssessmentId, pendingTab));
    }
  } catch (err) {
    dispatch({
      type: POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const UPDATE_PRODUCT_PREFERENCE_STATUS_LOADING = '@@vendorProductAssessmentSummary/UPDATE_PRODUCT_PREFERENCE_STATUS_LOADING';
export const UPDATE_PRODUCT_PREFERENCE_STATUS_SUCCESS = '@@vendorProductAssessmentSummary/UPDATE_PRODUCT_PREFERENCE_STATUS_SUCCESS';
export const UPDATE_PRODUCT_PREFERENCE_STATUS_FAILURE = '@@vendorProductAssessmentSummary/UPDATE_PRODUCT_PREFERENCE_STATUS_FAILURE';

export const updateProductPreferenceStatus = (formData, callback, productInformation = false) => async (dispatch) => {
  dispatch({ type: UPDATE_PRODUCT_PREFERENCE_STATUS_LOADING });

  const body = JSON.stringify(formData);
  try {
    const response = await axios.put(`vendor/productpreferences/${formData?.ProductPreferenceId}/action/updatestatus`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: UPDATE_PRODUCT_PREFERENCE_STATUS_FAILURE,
      });
    } else {
      dispatch({
        type: UPDATE_PRODUCT_PREFERENCE_STATUS_SUCCESS,
        payload: response?.data?.Data,
        productInformation: productInformation,
      });
      if (callback) {
        callback(response?.data?.Data);
      }
      if (productInformation) {
        dispatch(searchVendorProductInformation())
      } else {
        dispatch(searchVendorProductPreferences())
      }
    }
  } catch (err) {
    dispatch({
      type: UPDATE_PRODUCT_PREFERENCE_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_PRODUCT_PREFERENCE_STATUS_ID = '@@vendorProduct/SET_PRODUCT_PREFERENCE_STATUS_ID';

export const setProductPreferenceStatusId = (val = null) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_PREFERENCE_STATUS_ID,
    payload: val,
  })
}

export const SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_LOADING = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_LOADING';
export const SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_FAILURE = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_FAILURE';

export const searchVendorProductDetailSUpcomingMaintenanceOverview = (assessmentId) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_LOADING });
  let body = getVendorProductDetailsUpcomingMaintenanceBody(assessmentId);
  try {
    const response = await axios.post('/assessment/productvendor/recommendations/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_SUCCESS,
        payload: response?.data.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_LOADING = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_LOADING';
export const SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_FAILURE = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_FAILURE';

export const searchVendorProductDetailSUpcomingMaintenance = (assessmentId) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_LOADING });
  let body = getVendorProductDetailsUpcomingMaintenanceBody(assessmentId);
  try {
    const response = await axios.post('/assessment/productvendor/recommendations/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((errorItem) => errorItem.Message !== NO_DATA_FOUND);
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_FAILURE,
      });
      dispatch(setErrors(errors));
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_SUCCESS,
        payload: response?.data.Data?.Rows,
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_PRODUCT_PREFERENCE_REVIEW = '@@vendorProduct/SET_PRODUCT_PREFERENCE_REVIEW';

export const setProductPreferenceReview = (val = false) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_PREFERENCE_REVIEW,
    payload: val,
  })
}

export const SET_PRODUCT_PROFILE_WIZARD_TAB_VALUE = '@@vendorProduct/SET_PRODUCT_PROFILE_WIZARD_TAB_VALUE';

export const setProductProfileWizardTabValue = (val = PRODUCT_PROFILE_WIZARD_TAB_OPTIONS.PRODUCT_CATALOG_PREFERENCES) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_PROFILE_WIZARD_TAB_VALUE,
    payload: val,
  })
}

// product information wizard actions
export const SEARCH_VENDOR_PRODUCT_INFORMATION_LOADING = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_INFORMATION_LOADING';
export const SEARCH_VENDOR_PRODUCT_INFORMATION_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_INFORMATION_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_INFORMATION_FAILURE = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_INFORMATION_FAILURE';

export const searchVendorProductInformation = (currentIndex = null, isReview) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_INFORMATION_LOADING });
  try {
    const body = searchVendorProductPreferencesBody(PRODUCT_INFORMATION_WIZARD_QUESTION_TYPE);
    const response = await axios.post(`/vendor/productpreferences/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_INFORMATION_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_INFORMATION_SUCCESS,
        payload: response?.data.Data?.Rows,
        currentIndex: currentIndex,
        isReview: isReview
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_INFORMATION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_PRODUCT_INFORMATION_QUESTION_INDEX = '@@vendorProduct/SET_PRODUCT_INFORMATION_QUESTION_INDEX';

export const setProductInformationQuestionIndex = (index = DEFAULT_PRODUCT_INFORMATION_QUESTION_INDEX) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_INFORMATION_QUESTION_INDEX,
    payload: index,
  })
}

export const SET_PRODUCT_INFORMATION_QUESTION_LIST = '@@vendorProduct/SET_PRODUCT_INFORMATION_QUESTION_LIST';

export const setProductInformationQuestionList = (list = []) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_INFORMATION_QUESTION_LIST,
    payload: list,
  })
}

export const GET_PRODUCT_INFORMATION_IMAGE_URL_LOADING = '@@vendorProduct/GET_PRODUCT_INFORMATION_IMAGE_URL_LOADING';
export const GET_PRODUCT_INFORMATION_IMAGE_URL_SUCCESS = '@@vendorProduct/GET_PRODUCT_INFORMATION_IMAGE_URL_SUCCESS';
export const GET_PRODUCT_INFORMATION_IMAGE_URL_FAILURE = '@@vendorProduct/PRODUCT_INFORMATION_IMAGE_URL_FAILURE';

export const getProductInformationImageUrl = (productPreferenceQuestionId) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_INFORMATION_IMAGE_URL_LOADING });

  try {
    const body = searchProductProfileWizardImageBody(PRODUCT_INFORMATION_WIZARD_QUESTION_TYPE);
    const res = await axios.post(`/vendor/productpreferences/${productPreferenceQuestionId}/image/action/geturl`, body);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter(
        (it) => it.Message !== NO_DATA_FOUND
      );
      dispatch(setErrors(errors));
      dispatch({
        type: GET_PRODUCT_INFORMATION_IMAGE_URL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_PRODUCT_INFORMATION_IMAGE_URL_SUCCESS,
        payload: res?.data,
        ProductPreferenceQuestionId: productPreferenceQuestionId
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PRODUCT_INFORMATION_IMAGE_URL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_LOADING = '@@vendorProduct/POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_LOADING';
export const POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_SUCCESS = '@@vendorProduct/POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_SUCCESS';
export const POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_FAILURE = '@@vendorProduct/POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_FAILURE';

export const saveVendorProductInformation = (data, currentIndex, isReview) => async (dispatch) => {
  dispatch({ type: POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_LOADING });
  try {
    const body = JSON.stringify(data);
    const response = await axios.post(`/vendor/productpreferenceresponse`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_SUCCESS,
        payload: response?.data,
        currentIndex: currentIndex,
        isReview: isReview
      });
      dispatch(searchVendorProductInformation(currentIndex, isReview));
    }
  } catch (err) {
    dispatch({
      type: POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_PRODUCT_INFO_REVIEW = '@@vendorProduct/SET_PRODUCT_INFO_REVIEW';

export const setProductInformationReview = (val = false) => async (dispatch) => {
  dispatch({
    type: SET_PRODUCT_INFO_REVIEW,
    payload: val,
  })
}

export const SEARCH_VENDOR_PRODUCT_LOADING = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_LOADING';
export const SEARCH_VENDOR_PRODUCT_SUCCESS = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_SUCCESS';
export const SEARCH_VENDOR_PRODUCT_FAILURE = '@@vendorProduct/SEARCH_VENDOR_PRODUCT_FAILURE';

export const searchVendorProduct = (productName, formData, currentIndex = null, isReview) => async (dispatch) => {
  dispatch({ type: SEARCH_VENDOR_PRODUCT_LOADING });

  let warning = [
    {
      Type: 'Error',
      Message: PRODUCT_ALREADY_EXISTS.replace('XXXXX', productName),
      Source: null,
    },
  ];

  let body = searchVendorProductBody(productName);
  try {
    const response = await axios.post(`/vendor/product/search`, body);
    if (response?.data?.ErrorCount > 0) {
      if (response?.data?.Errors.filter((it) => it.Message === NO_DATA_FOUND).length > 0) {
        dispatch(saveVendorProductInformation(formData, currentIndex, isReview));
      } else {
        const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
      }
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      dispatch(setErrors(warning));
    }
  } catch (err) {
    dispatch({
      type: SEARCH_VENDOR_PRODUCT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SET_OPEN_HELP_MODAL = '@@vendorProduct/SET_OPEN_HELP_MODAL';

export const setOpenHelpModal = (val = false, helpModalType = null) => async (dispatch) => {
  dispatch({
    type: SET_OPEN_HELP_MODAL,
    payload: val,
    helpModalType: helpModalType
  })
}
export const POST_HELP_TICKET_LOADING = '@vendorProduct/POST_HELP_TICKET_LOADING';
export const POST_HELP_TICKET_SUCCESS = '@vendorProduct/POST_HELP_TICKET_SUCCESS';
export const POST_HELP_TICKET_FAILURE = '@vendorProduct/POST_HELP_TICKET_FAILURE';

export const submitHelpTicket = (formData, productVendorId, activeTab) => async (dispatch) => {
  dispatch({
    type: POST_HELP_TICKET_LOADING,
  });

  let tempFormData = {
    ...formData,
    AssessmentId: productVendorId,
  };

  delete tempFormData['UploadedFileList'];

  let body = new FormData();

  Object.keys(tempFormData).forEach((key) => {
    body.append(key, JSON.stringify(tempFormData[key]));
  });
  formData?.UploadedFileList.forEach((helpTicketFile) => {
    body.append('UploadedFileList', helpTicketFile.file);
  });

  try {
    const res = await axios.post(`/assessmentticket`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_HELP_TICKET_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: POST_HELP_TICKET_SUCCESS,
        payload: res?.data?.Data,
      });
      dispatch(searchVendorProductDetailsRecommendationsTotal(productVendorId));
      dispatch(searchVendorProductDetailsRecommendations(productVendorId, activeTab));
    }
  } catch (err) {
    dispatch({
      type: POST_HELP_TICKET_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SUBMIT_IPI_MANAGEMENT_LOADING = '@@vendorProduct/SUBMIT_IPI_MANAGEMENT_LOADING';
export const SUBMIT_IPI_MANAGEMENT_SUCCESS = '@@vendorProduct/SUBMIT_IPI_MANAGEMENT_SUCCESS';
export const SUBMIT_IPI_MANAGEMENT_FAILURE = '@@vendorProduct/SUBMIT_IPI_MANAGEMENT_FAILURE';

export const submitIPIManagement = (formData, productVendorId = null) => async (dispatch) => {
  dispatch({
    type: SUBMIT_IPI_MANAGEMENT_LOADING,
  });

  let tempFormData = {
    ...formData,
  };
  let attachEvidenceList = [];

  delete tempFormData["UploadedFile"];

  let body = new FormData();

  Object.keys(tempFormData).forEach((key) => {
    body.append(key, JSON.stringify(tempFormData[key]));
  });

  formData?.UploadedFile.forEach((item) => {
    if (!isNil(item?.EvidenceId)) {
      attachEvidenceList.push({ EvidenceId: item?.EvidenceId, FileName: item?.FileName });
    } else {
      body.append(`UploadedFileList`, item?.UploadedFile);
    }
  });

  if (attachEvidenceList?.length > 0) {
    body.append(`AssessmentRequirementIPIEvidenceList`, JSON.stringify(attachEvidenceList));
  };

  try {
    const response = await axios.post(`/assessment/requirement/ipi/action/submit`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: SUBMIT_IPI_MANAGEMENT_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: SUBMIT_IPI_MANAGEMENT_SUCCESS,
        payload: response?.data?.Data,
      });
      dispatch(searchVendorProductDetailSUpcomingMaintenance(productVendorId));
    }
  } catch (err) {
    dispatch({
      type: SUBMIT_IPI_MANAGEMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_CORL_CLEARED_ASSESSMENT_REQUEST_LOADING = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_REQUEST_LOADING';
export const ADD_CORL_CLEARED_ASSESSMENT_REQUEST_SUCCESS = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_REQUEST_SUCCESS';
export const ADD_CORL_CLEARED_ASSESSMENT_REQUEST_FAILURE = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_REQUEST_FAILURE';

export const addCorlClearedAssessmentRequest = (data, callback) => async (dispatch) => {
  dispatch({ type: ADD_CORL_CLEARED_ASSESSMENT_REQUEST_LOADING });
  const body = getCorlClearedAssessmentRequestBody(data);
  try {
    const response = await axios.post('serviceprovider/vendor/product/assessmentrequest', body, {
      timeout: CORL_CLEARED_ASSESSMENT_TIMEOUT,
    });
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_CORL_CLEARED_ASSESSMENT_REQUEST_FAILURE,
      });
    } else {
      dispatch({
        type: ADD_CORL_CLEARED_ASSESSMENT_REQUEST_SUCCESS,
        payload: response?.data?.Data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: ADD_CORL_CLEARED_ASSESSMENT_REQUEST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_CORL_CLEARED_ASSESSMENT_LOADING = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_LOADING';
export const ADD_CORL_CLEARED_ASSESSMENT_SUCCESS = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_SUCCESS';
export const ADD_CORL_CLEARED_ASSESSMENT_FAILURE = '@@vendorProduct/ADD_CORL_CLEARED_ASSESSMENT_FAILURE';

export const addCorlClearedAssessment = (data, callback) => async (dispatch) => {
  dispatch({ type: ADD_CORL_CLEARED_ASSESSMENT_LOADING });
  const body = getCorlClearedAssessmentBody(data);
  try {
    const response = await axios.post(`serviceprovider/vendor/product/${data.ProductVendorId}/assessment`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: ADD_CORL_CLEARED_ASSESSMENT_FAILURE,
      });
    } else {
      dispatch({
        type: ADD_CORL_CLEARED_ASSESSMENT_SUCCESS,
        payload: response?.data?.Data,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: ADD_CORL_CLEARED_ASSESSMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};