import { SET_LOADING_EXPANDED_SECTIONS } from 'actions/assessmentResults';
import {
  SEARCH_ACTIVE_ASSESSMENTS_FAILURE,
  SEARCH_ACTIVE_ASSESSMENTS_LOADING,
  SEARCH_ACTIVE_ASSESSMENTS_SUCCESS,
  SEARCH_ASSESSMENTS_LIST_FAILURE,
  SEARCH_ASSESSMENTS_LIST_LOADING,
  SEARCH_ASSESSMENTS_LIST_SUCCESS,
  SEARCH_ASSESSMENT_CONFIG_FAILURE,
  SEARCH_ASSESSMENT_CONFIG_LOADING,
  SEARCH_ASSESSMENT_CONFIG_SUCCESS,
  SEARCH_COMPLETED_ASSESSMENTS_FAILURE,
  SEARCH_COMPLETED_ASSESSMENTS_LOADING,
  SEARCH_COMPLETED_ASSESSMENTS_SUCCESS,
  SEARCH_REMEDIATION_ASSESSMENTS_FAILURE,
  SEARCH_REMEDIATION_ASSESSMENTS_LOADING,
  SEARCH_REMEDIATION_ASSESSMENTS_SUCCESS,
  SET_ASSESSMENTS_OVERVIEW_TAB,
  SET_SELECTED_CARD_DETAILS,
  SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_LOADING,
  SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_SUCCESS,
  SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_FAILURE,
  POST_ASSESSMENT_TICKET_LOADING,
  POST_ASSESSMENT_TICKET_SUCCESS,
  POST_ASSESSMENT_TICKET_FAILURE,
  SET_OPEN_ASSESSMENT_HELP_MODAL,
  SET_SELECTED_ASSESSMENT_DETAILS_CARD,
  SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET,
  GET_ASSESSMENT_NOTES_LOADING,
  GET_ASSESSMENT_NOTES_SUCCESS,
  GET_ASSESSMENT_NOTES_FAILURE,
  GET_QUESTIONNAIRE_INFO_LOADING,
  GET_QUESTIONNAIRE_INFO_SUCCESS,
  GET_QUESTIONNAIRE_INFO_FAILURE,
  SET_ASSESSMENT_NOTES,
  SEARCH_ASSESSMENT_LOADING,
  SEARCH_ASSESSMENT_SUCCESS,
  SEARCH_ASSESSMENT_FAILURE,
  SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_LOADING,
  SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_SUCCESS,
  SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_FAILURE,
  SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_LOADING,
  SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_SUCCESS,
  SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_FAILURE,
  RESET_ASSESSMENT_DETAILS,
  SEARCH_REMEDIATION_GUIDANCE_LIST_LOADING,
  SEARCH_REMEDIATION_GUIDANCE_LIST_SUCCESS,
  SEARCH_REMEDIATION_GUIDANCE_LIST_FAILURE,
  SEARCH_REMEDIATION_GUIDANCE_LOADING,
  SEARCH_REMEDIATION_GUIDANCE_SUCCESS,
  SEARCH_REMEDIATION_GUIDANCE_FAILURE,
  SEARCH_REMEDIATION_GUIDANCE_DETAILS_LOADING,
  SEARCH_REMEDIATION_GUIDANCE_DETAILS_SUCCESS,
  SEARCH_REMEDIATION_GUIDANCE_DETAILS_FAILURE,
  ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_LOADING,
  ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_SUCCESS,
  ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_FAILURE,
  ACCEPT_RISK_FINDINGS_WITH_MILESTONE_LOADING,
  ACCEPT_RISK_FINDINGS_WITH_MILESTONE_SUCCESS,
  ACCEPT_RISK_FINDINGS_WITH_MILESTONE_FAILURE,
  ACCEPT_ALL_RISK_FINDING_LOADING,
  ACCEPT_ALL_RISK_FINDING_SUCCESS,
  ACCEPT_ALL_RISK_FINDING_FAILURE,
  SUBMIT_REMEDIATION_PLAN_LOADING,
  SUBMIT_REMEDIATION_PLAN_SUCCESS,
  SUBMIT_REMEDIATION_PLAN_FAILURE,
  SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_LOADING,
  SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_SUCCESS,
  SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_FAILURE,
  GET_DECLINE_RISK_FINDING_LOOKUP_LOADING,
  GET_DECLINE_RISK_FINDING_LOOKUP_SUCCESS,
  GET_DECLINE_RISK_FINDING_LOOKUP_FAILURE,
  SET_OPEN_REMEDIATION_HELP_MODAL,
  POST_REMEDIATION_HELP_TICKET_LOADING,
  POST_REMEDIATION_HELP_TICKET_SUCCESS,
  POST_REMEDIATION_HELP_TICKET_FAILURE,
  POST_REMEDIATION_SUBMIT_FOR_APPROVAL_LOADING,
  POST_REMEDIATION_SUBMIT_FOR_APPROVAL_SUCCESS,
  POST_REMEDIATION_SUBMIT_FOR_APPROVAL_FAILURE,
  RESET_REMEDIATION_GUIDANCE_DETAILS_LIST,
} from 'actions/assessmentsOverview';
import { DYNAMIC } from 'constants/constants';
import { format, parse, formatDistance, intlFormatDistance } from 'date-fns';
import { isEmpty, isNil } from 'lodash';
import { ASSESSMENT_HELP_TICKET_CREATED_SUCCESSFULLY_MESSAGE, REMEDIATION_HELP_TICKET_CREATED_SUCCESSFULLY_MESSAGE } from 'components/Common/AlertMessages';
import { ASSESSMENT_OVERVIEW_TAB_OPTIONS } from 'pages/AssessmentsOverview/constants';
import { convertToLocalTime, convertUTCDateToLocalDate } from 'utils/dateAndTimeUtils';
import { getSelectOptionsList } from 'utils/arrayUtils';
import { toast } from 'react-toastify';
import { NEW } from 'pages/AssessmentDetails/RiskFindingManagement/constants';
import { ACCEPTED_SUCCESSFULLY } from 'pages/ProductDetails/constants';
import { COUNT_OF_HIGH_RISK_FINDINGS, IS_REM_RESPONSE_SUBMITTED, REMEDIATION_PLAN_SUBMITTED_SUCCESSFULLY, RISK_FINDING_ACCEPTED_SUCCESSFULLY, TOTAL_COUNT_OF_RISK_FINDINGS } from 'pages/AssessmentDetails/constants';
import { ERROR_MESSAGE } from 'constants/errorMessage';

const initialState = {
  loading: false,
  loadingCount: 0,
  activeAssessments: [],
  completedAssessments: [],
  remediationAssessments: [],
  assessmentConfigurationStatuses: [],
  assessmentsOverviewTab: ASSESSMENT_OVERVIEW_TAB_OPTIONS.BOARD,
  selectedAssessmentCard: {},
  assessmentDetails: [],
  loadingExpandedSections: false,
  openAssessmentHelpModal: false,
  assessmentList: [],
  selectedAssessment: {},
  openAssessmentDetailsSidesheet: false,
  assessmentNotes: [],
  totalAssessmentInfo: {},
  totalQuestionnaireInfo: [],
  scopingQuestionnaireInfo: [],
  controlQuestionnaireInfo: [],
  supplementalQuestionnaireInfo: [],
  assessmentData: {},
  assessmentPortal1RedirectURL: null,
  assessmentDynamicDetails: [],
  remdiationRailMenu: [],
  remediationGuidanceDetails: {},
  remediationGuidance: [],
  remediationGuidanceDetailsList: [],
  remediationListTotal: 0,
  totalActiveAssesssment: 0,
  totalAssessmentInRemediation: 0,
  totalCompletedAssessment: 0,
  activeAssessmentLoading: false,
  remediationAssessmentLoading: false,
  remediationListResponded: 0,
  declineRiskFindingLookup: [],
  openRemediationHelpModal: false,
  isGuidance: false,
  totalCountOfRiskFindings: 0,
  countOfHighRistFindings: 0,
  isRemResponseSubmitted: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ASSESSMENTS_OVERVIEW_TAB: {
      return {
        ...state,
        assessmentsOverviewTab: payload,
      };
    }

    case GET_ASSESSMENT_NOTES_LOADING:
    case GET_QUESTIONNAIRE_INFO_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SEARCH_ACTIVE_ASSESSMENTS_LOADING: {
      return {
        ...state,
        activeAssessmentLoading: true,
      }
    }

    case SEARCH_ACTIVE_ASSESSMENTS_SUCCESS: {
      let tempData = [];
      payload[0]?.ActiveAssessmentList?.forEach((item) => {
        let parsedLastUpdated = null;
        if (item?.Source !== DYNAMIC) {
          parsedLastUpdated = format(parse(item?.LastUpdated, "yyyy-MM-dd'T'HH:mm:ss", new Date()), 'MM/dd/yyyy HH:mm:ss');
        } else {
          parsedLastUpdated = item?.LastUpdated;
        }
        return tempData.push({
          ...item,
          LastUpdated: isEmpty(item?.LastUpdated) ? null : convertToLocalTime(item?.LastUpdated),
          AssessmentStartDate: isEmpty(item?.AssessmentStartDate) ? null : convertToLocalTime(item?.AssessmentStartDate),
          DueDate: isEmpty(item?.DueDate) ? null : convertToLocalTime(item?.DueDate),
          AssessmentCompletedDate: isEmpty(item?.AssessmentCompletedDate) ? null : convertToLocalTime(item?.AssessmentCompletedDate),
          LastStatusActivity:
            !isEmpty(item) && !isEmpty(parsedLastUpdated)
              ? intlFormatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date())?.includes('quarter')
                ? formatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date(), { addSuffix: true })
                : intlFormatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date())
              : null,
        });
      });
      return {
        ...state,
        activeAssessmentLoading: false,
        activeAssessments: tempData,
        totalActiveAssesssment: payload[0]?.CountOfActiveAssessment,
      };
    }
    case SEARCH_ACTIVE_ASSESSMENTS_FAILURE: {
      return {
        ...state,
        activeAssessmentLoading: false,
        activeAssessments: [],
        totalActiveAssesssment: 0,
      };
    }
    case SEARCH_COMPLETED_ASSESSMENTS_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_COMPLETED_ASSESSMENTS_SUCCESS: {
      let tempData = [];
      payload[0]?.CompletedAssessmentList?.forEach((item) => {
        let parsedLastUpdated = null;
        if (item?.Source !== DYNAMIC) {
          parsedLastUpdated = format(parse(item?.LastUpdated, "yyyy-MM-dd'T'HH:mm:ss", new Date()), 'MM/dd/yyyy HH:mm:ss');
        } else {
          parsedLastUpdated = item?.LastUpdated;
        }
        return tempData.push({
          ...item,
          LastUpdated: isEmpty(item?.LastUpdated) ? null : convertToLocalTime(item?.LastUpdated),
          AssessmentStartDate: isEmpty(item?.AssessmentStartDate) ? null : convertToLocalTime(item?.AssessmentStartDate),
          AssessmentCompletedDate: isEmpty(item?.AssessmentCompletedDate) ? null : convertToLocalTime(item?.AssessmentCompletedDate),
          DueDate: isEmpty(item?.DueDate) ? null : convertToLocalTime(item?.DueDate),
          LastStatusActivity:
            !isEmpty(item) && !isEmpty(parsedLastUpdated)
              ? intlFormatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date())?.includes('quarter')
                ? formatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date(), { addSuffix: true })
                : intlFormatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date())
              : null,
        });
      });
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        completedAssessments: tempData,
        totalCompletedAssessment: payload[0]?.CountOfCompletedAssessment
      };
    }
    case SEARCH_COMPLETED_ASSESSMENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        completedAssessments: [],
        totalCompletedAssessment: 0,
      };
    }
    case SEARCH_REMEDIATION_ASSESSMENTS_LOADING: {
      return {
        ...state,
        remediationAssessmentLoading: true,
      };
    }
    case SEARCH_REMEDIATION_ASSESSMENTS_SUCCESS: {
      let tempData = [];
      payload[0]?.RemediationAssessmentList?.forEach((item) => {
        let parsedLastUpdated = null;
        if (item?.Source !== DYNAMIC) {
          parsedLastUpdated = format(parse(item?.LastUpdated, "yyyy-MM-dd'T'HH:mm:ss", new Date()), 'MM/dd/yyyy HH:mm:ss');
        } else {
          parsedLastUpdated = item?.LastUpdated;
        }
        return tempData.push({
          ...item,
          LastUpdated: isEmpty(item?.LastUpdated) ? null : convertToLocalTime(item?.LastUpdated),
          AssessmentCompletedDate: isEmpty(item?.AssessmentCompletedDate) ? null : convertToLocalTime(item?.AssessmentCompletedDate),
          DueDate: isEmpty(item?.DueDate) ? null : convertToLocalTime(item?.DueDate),
          AssessmentStartDate: isEmpty(item?.AssessmentStartDate) ? null : convertToLocalTime(item?.AssessmentStartDate),
          LastStatusActivity:
            !isEmpty(item) && !isEmpty(parsedLastUpdated)
              ? intlFormatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date())?.includes('quarter')
                ? formatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date(), { addSuffix: true })
                : intlFormatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date())
              : null,
        });
      });
      return {
        ...state,
        remediationAssessmentLoading: false,
        remediationAssessments: tempData,
        totalAssessmentInRemediation: payload[0]?.CountOfRemediationAssessment,
      };
    }
    case SEARCH_REMEDIATION_ASSESSMENTS_FAILURE: {
      return {
        ...state,
        remediationAssessmentLoading: false,
        remediationAssessments: [],
        totalAssessmentInRemediation: 0,
      };
    }
    case SEARCH_ASSESSMENTS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_ASSESSMENTS_LIST_SUCCESS: {
      let tempData = [];
      payload?.forEach((item, index) => {
        let parsedLastUpdated = null;
        if (item?.Source !== DYNAMIC) {
          parsedLastUpdated = format(parse(item?.LastUpdated, "yyyy-MM-dd'T'HH:mm:ss", new Date()), 'MM/dd/yyyy HH:mm:ss');
        } else {
          parsedLastUpdated = item?.LastUpdated;
        }
        return tempData.push({
          ...item,
          id: index,
          LastUpdated: isEmpty(item?.LastUpdated) ? null : convertToLocalTime(item?.LastUpdated),
          AssessmentStartDate: isEmpty(item?.AssessmentStartDate) ? null : convertToLocalTime(item?.AssessmentStartDate),
          AssessmentCompletedDate: isEmpty(item?.AssessmentCompletedDate) ? null : convertToLocalTime(item?.AssessmentCompletedDate),
          DueDate: isEmpty(item?.DueDate) ? null : convertToLocalTime(item?.DueDate),
          LastStatusActivity:
            !isEmpty(item) && !isEmpty(parsedLastUpdated)
              ? intlFormatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date())?.includes('quarter')
                ? formatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date(), { addSuffix: true })
                : intlFormatDistance(new Date(convertUTCDateToLocalDate(parsedLastUpdated)), new Date())
              : null,
        });
      });
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentList: tempData,
      };
    }
    case SEARCH_ASSESSMENTS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentList: [],
      };
    }
    case SEARCH_ASSESSMENT_CONFIG_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentConfigurationStatuses: getSelectOptionsList(payload?.Data?.Rows, 'AssessmentDisplayStatus', 'AssessmentDisplayStatus'),
      };
    }

    case SEARCH_ASSESSMENT_CONFIG_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentConfigurationStatuses: [],
      };
    }

    case SET_SELECTED_CARD_DETAILS: {
      return {
        ...state,
        selectedAssessmentCard: payload,
      };
    }

    case SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentDynamicDetails: [],
        assessmentDetails:
          payload?.length > 0 &&
          payload.map((item) => {
            return {
              ...item,
              LastUpdated:
                !isEmpty(item) && !isEmpty(item?.LastUpdated)
                  ? intlFormatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date())?.includes('quarter')
                    ? formatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date(), { addSuffix: true })
                    : intlFormatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date())
                  : null,
              DueDate: !isNil(item?.DueDate) ? convertToLocalTime(item?.DueDate, 'MM/dd/yy') : '-',
              AssessmentQuestionnaireTemplateList: item?.AssessmentQuestionnaireTemplateList?.map((item, index) => {
                return {
                  ...item,
                  id: index,
                  LastUpdated:
                    !isEmpty(item) && !isEmpty(item?.LastUpdated)
                      ? intlFormatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date())?.includes('quarter')
                        ? formatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date(), { addSuffix: true })
                        : intlFormatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date())
                      : null,
                  ResponseLastUpdated:
                    !isEmpty(item) && !isEmpty(item?.ResponseLastUpdated)
                      ? intlFormatDistance(new Date(convertUTCDateToLocalDate(item?.ResponseLastUpdated)), new Date())?.includes('quarter')
                        ? formatDistance(new Date(convertUTCDateToLocalDate(item?.ResponseLastUpdated)), new Date(), { addSuffix: true })
                        : intlFormatDistance(new Date(convertUTCDateToLocalDate(item?.ResponseLastUpdated)), new Date())
                      : null,
                };
              }),
            };
          }),
      };
    }

    case SEARCH_ASSESSMENT_OVERVIEW_PORTAL_ASSESMENT_DETAILS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentDetails: [],
      };
    }

    case SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentDetails: [],
        assessmentDynamicDetails:
          payload?.length > 0 &&
          payload.map((item) => {
            return {
              ...item,
              LastUpdated:
                !isEmpty(item) && !isEmpty(item?.LastUpdated)
                  ? intlFormatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date())?.includes('quarter')
                    ? formatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date(), { addSuffix: true })
                    : intlFormatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date())
                  : null,
              DueDate: !isNil(item?.DueDate) ? convertToLocalTime(item?.DueDate, 'MM/dd/yy') : '-',
              LegacyQuestionanireList: item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList?.map((item, index) => {
                return {
                  ...item,
                  id: index,
                  LastUpdated:
                    !isEmpty(item) && !isEmpty(item?.LastUpdated)
                      ? intlFormatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date())?.includes('quarter')
                        ? formatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date(), { addSuffix: true })
                        : intlFormatDistance(new Date(convertUTCDateToLocalDate(item?.LastUpdated)), new Date())
                      : null,
                };
              }),
              DisplayProgressName:
                item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList?.length > 0 &&
                  item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList[0]?.QuestionnaireType
                  ? item.QuestionnaireSummary.LegacyQuestionnaireTemplateList[0].QuestionnaireType
                  : '',
              TotalCountOfQuestions: item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList?.reduce((acc, template) => {
                return acc + (template.QuestionnaireQuestionCount || 0);
              }, 0),
              TotalCountOfResponses: item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList?.reduce((acc, template) => {
                return acc + (template.QuestionnaireResponseCount || 0);
              }, 0),
              TotalPercentageOfResponses: (() => {
                const totalData = item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList?.reduce((acc, template) => {
                  return {
                    sum: acc.sum + (template.QuesionnaireResponsePercentage || 0),
                    count: acc.count + 1
                  };
                }, { sum: 0, count: 0 });
                return totalData?.count > 0
                  ? totalData.sum / totalData.count
                  : 0;
              })(),
            };
          }),
      };
    }

    case SEARCH_ASSESSMENT_OVERVIEW_DYNAMIC_ASSESMENT_DETAILS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentDetails: [],
      };
    }

    case SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET: {
      return {
        ...state,
        openAssessmentDetailsSidesheet: payload,
      };
    }

    case SET_SELECTED_ASSESSMENT_DETAILS_CARD: {
      return {
        ...state,
        selectedAssessment: payload,
      };
    }

    case GET_ASSESSMENT_NOTES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentNotes: payload,
      };
    }

    case GET_ASSESSMENT_NOTES_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentNotes: [],
      };
    }

    case SET_ASSESSMENT_NOTES: {
      return {
        ...state,
        assessmentNotes: payload,
      };
    }

    case GET_QUESTIONNAIRE_INFO_SUCCESS: {
      let total = [],
        scoping = [],
        control = [],
        supplemental = [];
      payload.forEach((item) => {
        if (item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList.length > 0) {
          item?.QuestionnaireSummary?.LegacyQuestionnaireTemplateList?.forEach((element) => {
            if (element?.QuestionnaireTemplateType === 'scoping') {
              scoping.push(element);
            } else if (element?.QuestionnaireTemplateType === 'control' || element?.QuestionnaireTemplateType === 'Vendor Security Risk Assessment') {
              control.push(element);
            } else if (element?.QuestionnaireTemplateType === 'Supplemental') {
              supplemental.push(element);
            }
            total.push(element);
          });
        }
      });
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        totalQuestionnaireInfo: total,
        scopingQuestionnaireInfo: scoping,
        controlQuestionnaireInfo: control,
        supplementalQuestionnaireInfo: supplemental,
        totalAssessmentInfo: payload?.length > 0 ? payload[0] : {},
      };
    }

    case GET_QUESTIONNAIRE_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        totalQuestionnaireInfo: [],
        scopingQuestionnaireInfo: [],
        controlQuestionnaireInfo: [],
        supplementalQuestionnaireInfo: [],
        totalAssessmentInfo: {},
      };
    }

    case SET_LOADING_EXPANDED_SECTIONS: {
      return {
        ...state,
        loadingExpandedSections: payload,
      };
    }

    case POST_ASSESSMENT_TICKET_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case POST_ASSESSMENT_TICKET_SUCCESS: {
      toast.success(ASSESSMENT_HELP_TICKET_CREATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case SET_OPEN_ASSESSMENT_HELP_MODAL: {
      return {
        ...state,
        openAssessmentHelpModal: payload,
      };
    }

    case POST_ASSESSMENT_TICKET_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case SEARCH_ASSESSMENT_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SEARCH_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentData: payload?.Data?.Rows?.length > 0 && payload?.Data?.Rows[0],
      };
    }

    case SEARCH_ASSESSMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentData: {},
      };
    }

    case SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentPortal1RedirectURL: payload,
      };
    }
    case SEARCH_ASSESSMENT_PORTAL_1_REDIRECTION_URL_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        assessmentPortal1RedirectURL: null,
      };
    }

    case RESET_ASSESSMENT_DETAILS: {
      return {
        ...state,
        assessmentDetails: [],
        assessmentDynamicDetails: [],
      };
    }

    case SEARCH_REMEDIATION_GUIDANCE_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SEARCH_REMEDIATION_GUIDANCE_SUCCESS: {
      const { fieldName } = action;

      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        remediationGuidance: payload,
        totalCountOfRiskFindings: fieldName === TOTAL_COUNT_OF_RISK_FINDINGS ? payload[0]?.TotalCountOfRiskFindings : state.totalCountOfRiskFindings,
        countOfHighRistFindings: fieldName === COUNT_OF_HIGH_RISK_FINDINGS ? payload[0]?.CountOfHighRistFindings : state.countOfHighRistFindings,
        isRemResponseSubmitted: fieldName === IS_REM_RESPONSE_SUBMITTED ? payload[0]?.IsRemResponseSubmitted : state.isRemResponseSubmitted,
      };
    }

    case SEARCH_REMEDIATION_GUIDANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        remediationGuidance: [],
      };
    }

    case SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        remediationGuidanceDetailsList: payload,
      };
    }

    case SEARCH_REMEDIATION_GUIDANCE_DETAILS_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        remediationGuidanceDetailsList: [],
      };
    }

    case ACCEPT_ALL_RISK_FINDING_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case ACCEPT_ALL_RISK_FINDING_SUCCESS: {
      toast.success(RISK_FINDING_ACCEPTED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case ACCEPT_ALL_RISK_FINDING_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case SUBMIT_REMEDIATION_PLAN_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SUBMIT_REMEDIATION_PLAN_SUCCESS: {
      toast.success(REMEDIATION_PLAN_SUBMITTED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case SUBMIT_REMEDIATION_PLAN_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case SEARCH_REMEDIATION_GUIDANCE_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_REMEDIATION_GUIDANCE_LIST_SUCCESS: {
      let respondedTotal = payload?.filter((item) => item?.DisplayStatus?.toLowerCase() !== NEW);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        remdiationRailMenu: payload,
        remediationListTotal: payload?.length,
        remediationListResponded: respondedTotal?.length,
      };
    }
    case SEARCH_REMEDIATION_GUIDANCE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        remdiationRailMenu: [],
        remediationListTotal: 0,
        remediationListResponded: 0,
      };
    }
    case SEARCH_REMEDIATION_GUIDANCE_DETAILS_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_REMEDIATION_GUIDANCE_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        remediationGuidanceDetails: payload,
      };
    }
    case SEARCH_REMEDIATION_GUIDANCE_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        remediationGuidanceDetails: {},
      };
    }
    case ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case ACCEPT_RISK_FINDINGS_WITHOUT_MILESTONE_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case ACCEPT_RISK_FINDINGS_WITH_MILESTONE_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case ACCEPT_RISK_FINDINGS_WITH_MILESTONE_SUCCESS: {
      toast.success(ACCEPTED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case ACCEPT_RISK_FINDINGS_WITH_MILESTONE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case GET_DECLINE_RISK_FINDING_LOOKUP_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case GET_DECLINE_RISK_FINDING_LOOKUP_SUCCESS: {
      let lookup = [];
      payload?.forEach((item) => {
        lookup.push({
          label: item?.Reason,
          value: item?.Reason,
        });
      });
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        declineRiskFindingLookup: lookup,
      };
    }

    case GET_DECLINE_RISK_FINDING_LOOKUP_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        declineRiskFindingLookup: [],
      };
    }

    case SET_OPEN_REMEDIATION_HELP_MODAL: {
      return {
        ...state,
        openRemediationHelpModal: payload?.open,
        isGuidance: payload?.isGuidance,
      };
    }

    case POST_REMEDIATION_HELP_TICKET_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case POST_REMEDIATION_HELP_TICKET_SUCCESS: {
      toast.success(REMEDIATION_HELP_TICKET_CREATED_SUCCESSFULLY_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case POST_REMEDIATION_HELP_TICKET_FAILURE: {
      toast.error(ERROR_MESSAGE)
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case POST_REMEDIATION_SUBMIT_FOR_APPROVAL_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case POST_REMEDIATION_SUBMIT_FOR_APPROVAL_SUCCESS: {
      toast.success(REMEDIATION_PLAN_SUBMITTED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case POST_REMEDIATION_SUBMIT_FOR_APPROVAL_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case RESET_REMEDIATION_GUIDANCE_DETAILS_LIST: {
      return {
        ...state,
        remediationGuidanceDetailsList: [],
      };
    }

    default:
      return state;
  }
}
