import { isNil, cloneDeep, isEmpty, isArray } from 'lodash';
import {
  POST_PRODUCT_GROUP_TO_LIST_LOADING,
  POST_PRODUCT_GROUP_TO_LIST_SUCCESS,
  POST_PRODUCT_GROUP_TO_LIST_FAILURE,
  PUT_PRODUCT_GROUP_TO_LIST_LOADING,
  PUT_PRODUCT_GROUP_TO_LIST_SUCCESS,
  PUT_PRODUCT_GROUP_TO_LIST_FAILURE,
  DELETE_PRODUCT_GROUP_FROM_LIST_LOADING,
  DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS,
  DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE,
  POST_PRODUCT_TO_LIST_LOADING,
  POST_PRODUCT_TO_LIST_SUCCESS,
  POST_PRODUCT_TO_LIST_FAILURE,
  PUT_PRODUCT_TO_LIST_LOADING,
  PUT_PRODUCT_TO_LIST_SUCCESS,
  PUT_PRODUCT_TO_LIST_FAILURE,
  DELETE_PRODUCT_FROM_LIST_LOADING,
  DELETE_PRODUCT_FROM_LIST_SUCCESS,
  DELETE_PRODUCT_FROM_LIST_FAILURE,
  SEARCH_PRODUCT_GROUP_LIST_LOADING,
  SEARCH_PRODUCT_GROUP_LIST_SUCCESS,
  SEARCH_PRODUCT_GROUP_LIST_FAILURE,
  SEARCH_PRODUCT_LIST_LOADING,
  SEARCH_PRODUCT_LIST_SUCCESS,
  SEARCH_PRODUCT_LIST_FAILURE,
  SET_VENDORS_PRODUCTS_TAB_VALUE,
  SET_PRODUCT_DELETE_ERRORS,
  SET_PRODUCTS_GROUP_FORCE_DELETE,
  SEARCH_VENDOR_PRODUCT_BY_ID_LOADING,
  SEARCH_VENDOR_PRODUCT_BY_ID_SUCCESS,
  SEARCH_VENDOR_PRODUCT_BY_ID_FAILURE,
  COMMENT_ASSESSMENT_RESULT_LOADING,
  COMMENT_ASSESSMENT_RESULT_SUCCESS,
  COMMENT_ASSESSMENT_RESULT_FAILURE,
  SET_CC_CONGRATULATE_MODAL,
  SET_CC_ACCEPT_OR_DENY,
  SEARCH_VENDOR_CONTRACT_LIST_LOADING,
  SEARCH_VENDOR_CONTRACT_LIST_SUCCESS,
  SEARCH_VENDOR_CONTRACT_LIST_FAILURE,
  SEARCH_CORL_CLEARED_PRODUCT_COUNT_LOADING,
  SEARCH_CORL_CLEARED_PRODUCT_COUNT_SUCCESS,
  SEARCH_CORL_CLEARED_PRODUCT_COUNT_FAILURE,
  SEARCH_CORL_CLEARED_INFO_LOADING,
  SEARCH_CORL_CLEARED_INFO_SUCCESS,
  SEARCH_CORL_CLEARED_INFO_FAILURE,
  CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_LOADING,
  CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_SUCCESS,
  CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_FAILURE,
  SEARCH_VENDOR_PRODUCT_PREFERENCES_LOADING,
  SEARCH_VENDOR_PRODUCT_PREFERENCES_SUCCESS,
  SEARCH_VENDOR_PRODUCT_PREFERENCES_FAILURE,
  POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_LOADING,
  POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_SUCCESS,
  POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_FAILURE,
  GET_PRODUCT_PREFERENCE_IMAGE_URL_LOADING,
  GET_PRODUCT_PREFERENCE_IMAGE_URL_SUCCESS,
  GET_PRODUCT_PREFERENCE_IMAGE_URL_FAILURE,
  SET_PRODUCT_PREFERENCE_QUESTION_INDEX,
  SET_PRODUCT_PREFERENCE_QUESTION_LIST,
  POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_LOADING,
  POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_SUCCESS,
  POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_FAILURE,
  SEARCH_VENDOR_PRODUCT_ASSESSMENT_LOADING,
  SEARCH_VENDOR_PRODUCT_ASSESSMENT_SUCCESS,
  SEARCH_VENDOR_PRODUCT_ASSESSMENT_FAILURE,
  PUT_ASESSMENT_SHARED_STATUS_LOADING,
  PUT_ASESSMENT_SHARED_STATUS_SUCCESS,
  PUT_ASESSMENT_SHARED_STATUS_FAILURE,
  UPDATE_PRODUCT_PREFERENCE_STATUS_LOADING,
  UPDATE_PRODUCT_PREFERENCE_STATUS_SUCCESS,
  UPDATE_PRODUCT_PREFERENCE_STATUS_FAILURE,
  SET_PRODUCT_PREFERENCE_STATUS_ID,
  SET_EXPANDED_PRODUCT_RECOMMENDATION_ID,
  SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_LOADING,
  SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_SUCCESS,
  SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_FAILURE,
  SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_LOADING,
  SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_SUCCESS,
  SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_FAILURE,
  POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_LOADING,
  POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_SUCCESS,
  POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_FAILURE,
  SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_LOADING,
  SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_SUCCESS,
  SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_FAILURE,
  SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_LOADING,
  SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_SUCCESS,
  SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_FAILURE,
  SEARCH_VENDOR_PRODUCT_INFORMATION_SUCCESS,
  SEARCH_VENDOR_PRODUCT_INFORMATION_LOADING,
  SEARCH_VENDOR_PRODUCT_INFORMATION_FAILURE,
  SET_PRODUCT_INFORMATION_QUESTION_INDEX,
  SET_PRODUCT_INFORMATION_QUESTION_LIST,
  GET_PRODUCT_INFORMATION_IMAGE_URL_SUCCESS,
  GET_PRODUCT_INFORMATION_IMAGE_URL_LOADING,
  GET_PRODUCT_INFORMATION_IMAGE_URL_FAILURE,
  POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_LOADING,
  POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_SUCCESS,
  POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_FAILURE,
  SET_PRODUCT_INFO_REVIEW,
  SEARCH_VENDOR_PRODUCT_LOADING,
  SEARCH_VENDOR_PRODUCT_SUCCESS,
  SEARCH_VENDOR_PRODUCT_FAILURE,
  SET_PRODUCT_PREFERENCE_REVIEW,
  SET_OPEN_HELP_MODAL,
  POST_HELP_TICKET_FAILURE,
  POST_HELP_TICKET_SUCCESS,
  POST_HELP_TICKET_LOADING,
  SUBMIT_IPI_MANAGEMENT_SUCCESS,
  SUBMIT_IPI_MANAGEMENT_FAILURE,
  SUBMIT_IPI_MANAGEMENT_LOADING,
  ADD_CORL_CLEARED_ASSESSMENT_LOADING,
  ADD_CORL_CLEARED_ASSESSMENT_SUCCESS,
  ADD_CORL_CLEARED_ASSESSMENT_FAILURE,
  ADD_CORL_CLEARED_ASSESSMENT_REQUEST_LOADING,
  ADD_CORL_CLEARED_ASSESSMENT_REQUEST_SUCCESS,
  ADD_CORL_CLEARED_ASSESSMENT_REQUEST_FAILURE,
  SET_PRODUCT_PROFILE_WIZARD_TAB_VALUE
} from '../actions/vendorProduct';
import {
  ASSESSMENT_SHARED_STATUS_UPDATED_SUCCESSFULLY,
  CORL_CLEARED_ASSESSMENT_ADDED_SUCCESSFULLY,
  DEFAULT_PRODUCT_PREFERENCE_QUESTION_INDEX,
  PRODUCT_CORL_CLEARED,
  PRODUCT_CORL_CLEARED_PARTICIPANT,
  PRODUCT_CORL_CLEARED_REQUEST_SUBMITTED,
  PRODUCT_GET_CLEARED,
  PRODUCT_GROUP_ADDED_SUCCESSFULLY,
  PRODUCT_GROUP_DELETED_SUCCESSFULLY,
  PRODUCT_GROUP_UPDATED_SUCCESSFULLY,
  PRODUCT_NOT_CORL_CLEARED,
  PRODUCT_PREFERENCES_SET_SUCCESSFULLY,
  PRODUCT_PROFILE_WIZARD_TAB_OPTIONS,
  VENDORS_PRODUCTS_TAB_OPTIONS,
} from 'pages/Products/constants';
import { toast } from 'react-toastify';
import {
  ERROR_MESSAGE,
  FEEDBACK_PROVIDED_SUCCESSFULLY,
  PREFERENCE_SAVED_SUCCESSFULLY,
  PRODUCT_ADDED_SUCCESSFULLY,
  PRODUCT_DELETED_SUCCESSFULLY,
  PRODUCT_UPDATED_SUCCESSFULLY,
  SUBMITTED_SUCCESSFULLY,
} from 'constants/errorMessage';
import { getSelectOptionsList } from 'utils/arrayUtils';
import { ACCEPTED_SUCCESSFULLY, DENIED_SUCCESSFULLY, NO_EXPIRATION, TODAY_DUE_DAYS, OVERDUE, OVERVIEW_DAYS, PREFERENCE_DENY, PRODUCT_RECOMMENDATIONS_UPDATED_SUCCESSFULLY, TODAY } from 'pages/ProductDetails/constants';
import { NO, YES } from 'constants/constants';
import { convertToLocalTime, convertUTCDateToLocalDate } from 'utils/dateAndTimeUtils';
import { formatDistance, intlFormatDistance } from 'date-fns';
import { CORL_CLEARED_PARTICIPANT_STATUS_ID, CORL_CLEARED_STATUS_ID, NOT_CORL_CLEARED_STATUS_ID } from 'pages/Dashboard/constants';

const initialState = {
  loading: false,
  loadingCount: 0,
  loadingProductPreferences: false,
  loadingPreference: false,
  loadingSaveProductPreferences: false,
  productGroupNames: {},
  productGroupList: [],
  productsList: [],
  vendorProduct: {},
  deleteProductGroup: false,
  vendorProductsTabValue: VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS,
  autoCompleteProductGroup: [],
  vendorProductDeleteError: [],
  forceDelete: false,
  isCongratulate: false,
  isAcceptOrDeny: null,
  productPreferences: [],
  productPreferenceQuestionIndex: DEFAULT_PRODUCT_PREFERENCE_QUESTION_INDEX,
  vendorProductAssessmentList: [],
  productPreferenceQuestionList: [],
  productPreferenceStatusId: null,
  productRecommendationsExpandedId: null,
  productDetailsRecommendations: [],
  productDetailsRecommendationsTotal: [],
  productDetailsUpcomingMaintenanceOverview: [],
  productDetailsUpcomingMaintenance: [],
  productInformations: [],
  productInformationQuestionIndex: DEFAULT_PRODUCT_PREFERENCE_QUESTION_INDEX,
  productInformationQuestionList: [],
  productInformationStatusId: null,
  isProductInformationReview: false,
  isProductPreferenceReview: false,
  openHelpModal: false,
  helpModalType: null,
  productProfileWizardTabValue: PRODUCT_PROFILE_WIZARD_TAB_OPTIONS.PRODUCT_CATALOG_PREFERENCES
};

const getProductCORLClearedStatus = (product) => {
  if (product?.CORLClearedStatusId === CORL_CLEARED_STATUS_ID)
    return PRODUCT_CORL_CLEARED;
  else if (product?.CORLClearedStatusId == NOT_CORL_CLEARED_STATUS_ID && product?.AssessmentId == 0 && (isNil(product?.ExternalTicketId) || isEmpty(product?.ExternalTicketId)))
    return PRODUCT_GET_CLEARED
  else if (product?.CORLClearedStatusId == NOT_CORL_CLEARED_STATUS_ID && (!isNil(product?.ExternalTicketId) && !isEmpty(product?.ExternalTicketId)) && product?.AssessmentId == 0)
    return PRODUCT_CORL_CLEARED_REQUEST_SUBMITTED;
  else if (product?.CORLClearedStatusId == NOT_CORL_CLEARED_STATUS_ID && product?.AssessmentId > 0)
    return PRODUCT_NOT_CORL_CLEARED;
  else if (product?.CORLClearedStatusId == CORL_CLEARED_PARTICIPANT_STATUS_ID && product?.IsClearedProcessStarted)
    return PRODUCT_CORL_CLEARED_PARTICIPANT;
  else
    return PRODUCT_NOT_CORL_CLEARED;
}

export function vendorProductsPage(state = initialState, action) {
  const { type, payload, ProductPreferenceQuestionId } = action;

  switch (type) {
    case SEARCH_PRODUCT_GROUP_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_PRODUCT_GROUP_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productGroupList: payload,
        autoCompleteProductGroup: getSelectOptionsList(payload, 'ProductGroupName', 'ProductGroupId'),
      };
    }
    case SEARCH_PRODUCT_GROUP_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productGroupList: [],
        autoCompleteProductGroup: [],
      };
    }
    case POST_PRODUCT_GROUP_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case POST_PRODUCT_GROUP_TO_LIST_SUCCESS: {
      toast.success(PRODUCT_GROUP_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productGroup: payload,
      };
    }
    case POST_PRODUCT_GROUP_TO_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case PUT_PRODUCT_GROUP_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case PUT_PRODUCT_GROUP_TO_LIST_SUCCESS: {
      toast.success(PRODUCT_GROUP_UPDATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case PUT_PRODUCT_GROUP_TO_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case DELETE_PRODUCT_GROUP_FROM_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case DELETE_PRODUCT_GROUP_FROM_LIST_SUCCESS: {
      toast.success(PRODUCT_GROUP_DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        forceDelete: false,
      };
    }
    case DELETE_PRODUCT_GROUP_FROM_LIST_FAILURE: {
      if (!payload) {
        toast.error(ERROR_MESSAGE);
      }
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        forceDelete: payload,
      };
    }

    case SEARCH_PRODUCT_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SEARCH_PRODUCT_LIST_SUCCESS: {
      let tempData = [];
      payload.map((item) => {
        if (!isNil(item?.ProductGroupId) && item?.ProductGroupId > 0 && !isNil(item?.ProductGroupName)) {
          tempData.push({
            ...item,
            ProductGroup: {
              label: item?.ProductGroupName,
              value: item?.ProductGroupId,
            },
          });
        } else {
          tempData.push({ ...item, ProductGroup: '' });
        }
      });

      tempData = tempData.map((item, index) => {
        return {
          ...item,
          ProductCORLClearedStatus: getProductCORLClearedStatus(item),
        }
      });

      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productsList: tempData,
      };
    }

    case SEARCH_PRODUCT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productsList: [],
      };
    }

    case SEARCH_VENDOR_PRODUCT_BY_ID_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SEARCH_VENDOR_PRODUCT_BY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        vendorProduct: payload,
      };
    }

    case SEARCH_VENDOR_PRODUCT_BY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        vendorProduct: {},
      };
    }

    case POST_PRODUCT_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case POST_PRODUCT_TO_LIST_SUCCESS: {
      toast.success(PRODUCT_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case POST_PRODUCT_TO_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case PUT_PRODUCT_TO_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case PUT_PRODUCT_TO_LIST_SUCCESS: {
      toast.success(PRODUCT_UPDATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case PUT_PRODUCT_TO_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case DELETE_PRODUCT_FROM_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case DELETE_PRODUCT_FROM_LIST_SUCCESS: {
      toast.success(PRODUCT_DELETED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case DELETE_PRODUCT_FROM_LIST_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case SET_VENDORS_PRODUCTS_TAB_VALUE: {
      return {
        ...state,
        vendorProductsTabValue: payload || VENDORS_PRODUCTS_TAB_OPTIONS.PRODUCTS,
      };
    }
    case SET_PRODUCT_DELETE_ERRORS: {
      return {
        ...state,
        vendorProductDeleteError: payload,
      };
    }
    case SET_PRODUCTS_GROUP_FORCE_DELETE: {
      return {
        ...state,
        forceDelete: payload,
      };
    }
    case COMMENT_ASSESSMENT_RESULT_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case COMMENT_ASSESSMENT_RESULT_SUCCESS: {
      const { acceptStatusId } = action;
      acceptStatusId === PREFERENCE_DENY ? toast.success(DENIED_SUCCESSFULLY) : toast.success(ACCEPTED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case COMMENT_ASSESSMENT_RESULT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case SET_CC_CONGRATULATE_MODAL: {
      return {
        ...state,
        loading: false,
        isCongratulate: payload,
      };
    }
    case SET_CC_ACCEPT_OR_DENY: {
      return {
        ...state,
        loading: false,
        isAcceptOrDeny: payload,
      };
    }
    case SEARCH_VENDOR_CONTRACT_LIST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_VENDOR_CONTRACT_LIST_SUCCESS: {
      let tempData = {};
      tempData = {
        ...payload?.Data,
        IsCORLCustomer: payload?.Data?.IsCORLCustomer ? YES : NO,
      };
      Object.entries(tempData).forEach((item) => {
        const [key, value] = item;
        tempData[`${key}`] = isNil(value) || isArray(value) ? value : value.toString();
      });
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        contractDetails: tempData,
      };
    }
    case SEARCH_VENDOR_CONTRACT_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        contractDetails: {},
      };
    }
    case SEARCH_CORL_CLEARED_PRODUCT_COUNT_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_CORL_CLEARED_PRODUCT_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        corlClearedProductCount: payload?.CountOfClearedVendorProduct,
      };
    }
    case SEARCH_CORL_CLEARED_PRODUCT_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        corlClearedProductCount: 0,
      };
    }
    case SEARCH_CORL_CLEARED_INFO_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_CORL_CLEARED_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        corlClearedInfo: payload,
      };
    }
    case SEARCH_CORL_CLEARED_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        corlClearedInfo: [],
      };
    }
    case CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        vendorProduct: { ...state.vendorProduct, ...payload },
      };
    }

    case CREATE_PRODUCT_VENDOR_CLEARED_PROCESS_TICKET_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case SEARCH_VENDOR_PRODUCT_PREFERENCES_LOADING: {
      return {
        ...state,
        loadingProductPreferences: true,
      };
    }

    case SEARCH_VENDOR_PRODUCT_PREFERENCES_SUCCESS: {
      const { currentIndex, isReview } = action;
      const firstUnAnsweredQuestionIndex = payload?.findIndex(item => item?.QuestionResponseText === null || isEmpty(item?.QuestionResponseText));
      const firstUnAnsweredQuestion = payload?.filter(item => isEmpty(item?.QuestionResponseText));
      let resultIndex = null;
      if (isReview) {
        resultIndex = firstUnAnsweredQuestionIndex === -1 ? payload?.length : firstUnAnsweredQuestionIndex;
      } else if (!isNil(currentIndex)) {
        resultIndex = currentIndex + 1;
      } else {
        resultIndex = firstUnAnsweredQuestionIndex === -1 ? payload?.length : firstUnAnsweredQuestionIndex;
      }

      return {
        ...state,
        loadingProductPreferences: false,
        productPreferences: payload?.length > 0 ? payload?.map((item, index) => {
          return {
            ...item,
            index: index,
          }
        }) : [],
        productPreferenceQuestionIndex: resultIndex,
        productPreferenceStatusId: payload?.length > 0 ? payload[0]?.StatusId : null,
        firstUnAnsweredPreferenceQuestion: firstUnAnsweredQuestion,
      };
    }

    case SEARCH_VENDOR_PRODUCT_PREFERENCES_FAILURE: {
      return {
        ...state,
        loadingProductPreferences: false,
        productPreferences: [],
        productPreferenceStatusId: null,
      };
    }

    case POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_LOADING: {
      return {
        ...state,
        loadingPreference: true,
      };
    }

    case POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_SUCCESS: {
      return {
        ...state,
        loadingPreference: false,
      };
    }

    case POST_VENDOR_PRODUCT_PREFERENCE_RESPONSE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loadingPreference: false,
      };
    }

    case SET_PRODUCT_PREFERENCE_QUESTION_INDEX: {
      return {
        ...state,
        productPreferenceQuestionIndex: payload,
      };
    }

    case GET_PRODUCT_PREFERENCE_IMAGE_URL_LOADING: {
      return {
        ...state,
        urlLoading: true,
      };
    }

    case GET_PRODUCT_PREFERENCE_IMAGE_URL_SUCCESS: {
      const downloadUrl = payload?.Data?.DownloadUrl;
      const productPreferenceQuestion = state?.productPreferenceQuestionList?.find(item => item?.ProductPreferenceQuestionId === ProductPreferenceQuestionId);
      productPreferenceQuestion["QuestionImageUrl"] = downloadUrl;
      let tempData = state?.productPreferenceQuestionList?.map(item => {
        if (item?.ProductPreferenceQuestionId === ProductPreferenceQuestionId) {
          return productPreferenceQuestion;
        } else {
          return item;
        }
      });

      return {
        ...state,
        urlLoading: false,
        productPreferenceQuestionList: tempData,
      }
    }

    case GET_PRODUCT_PREFERENCE_IMAGE_URL_FAILURE: {
      return {
        ...state,
        urlLoading: false,
      }
    }

    case SET_PRODUCT_PREFERENCE_QUESTION_LIST: {
      return {
        ...state,
        productPreferenceQuestionList: payload,
      }
    }

    case POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_LOADING: {
      return {
        ...state,
        loadingSaveProductPreferences: true,
      };
    }

    case POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_SUCCESS: {
      return {
        ...state,
        loadingSaveProductPreferences: false,
        productPreferences: payload?.Data?.Rows?.length > 0 ? payload?.Data?.Rows : state?.productPreferences,
      };
    }

    case POST_VENDOR_PRODUCT_PREFERENCES_RESPONSE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loadingSaveProductPreferences: false,
      };
    }

    case SET_PRODUCT_PREFERENCE_REVIEW: {
      return {
        ...state,
        isProductPreferenceReview: payload,
      };
    }

    case SEARCH_VENDOR_PRODUCT_ASSESSMENT_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SEARCH_VENDOR_PRODUCT_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        vendorProductAssessmentList: payload.map((it, index) => {
          return {
            ...it,
            id: index,
            LastUpdated:
              !isEmpty(it) && !isEmpty(it?.LastUpdatedAssessment)
                ? intlFormatDistance(new Date(convertUTCDateToLocalDate(it?.LastUpdatedAssessment)), new Date())?.includes('quarter')
                  ? formatDistance(new Date(convertUTCDateToLocalDate(it?.LastUpdatedAssessment)), new Date(), { addSuffix: true })
                  : intlFormatDistance(new Date(convertUTCDateToLocalDate(it?.LastUpdatedAssessment)), new Date())
                : null,
          };
        }),
      };
    }

    case SEARCH_VENDOR_PRODUCT_ASSESSMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        vendorProductAssessmentList: [],
      };
    }

    case PUT_ASESSMENT_SHARED_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case PUT_ASESSMENT_SHARED_STATUS_SUCCESS: {
      toast.success(ASSESSMENT_SHARED_STATUS_UPDATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case PUT_ASESSMENT_SHARED_STATUS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case UPDATE_PRODUCT_PREFERENCE_STATUS_LOADING: {
      return {
        ...state,
        loadingProductPreferences: true,
      };
    }

    case UPDATE_PRODUCT_PREFERENCE_STATUS_SUCCESS: {
      const { productInformation } = action;

      toast.success(!productInformation ? PRODUCT_PREFERENCES_SET_SUCCESSFULLY : PRODUCT_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loadingProductPreferences: false,
      };
    }

    case UPDATE_PRODUCT_PREFERENCE_STATUS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loadingProductPreferences: false,
      };
    }

    case SET_PRODUCT_PREFERENCE_STATUS_ID: {
      return {
        ...state,
        productPreferenceStatusId: payload,
      };
    }

    case SEARCH_VENDOR_PRODUCT_INFORMATION_LOADING: {
      return {
        ...state,
        loadingProductInformations: true,
      };
    }

    case SEARCH_VENDOR_PRODUCT_INFORMATION_SUCCESS: {
      const { currentIndex, isReview } = action;
      const firstUnAnsweredQuestionIndex = payload?.findIndex(item => item?.QuestionResponseText === null || isEmpty(item?.QuestionResponseText));
      const firstUnAnsweredQuestion = payload?.filter(item => isEmpty(item?.QuestionResponseText));
      let resultIndex = null;
      if (isReview) {
        resultIndex = firstUnAnsweredQuestionIndex === -1 ? payload?.length : firstUnAnsweredQuestionIndex;
      } else if (!isNil(currentIndex)) {
        resultIndex = currentIndex + 1;
      } else {
        resultIndex = firstUnAnsweredQuestionIndex === -1 ? payload?.length : firstUnAnsweredQuestionIndex;
      }

      return {
        ...state,
        loadingProductInformations: false,
        productInformations: payload?.length > 0 ? payload?.map((item, index) => {
          return {
            ...item,
            index: index,
          }
        }) : [],
        productInformationQuestionIndex: resultIndex,
        productInformationStatusId: payload?.length > 0 ? payload[0]?.StatusId : null,
        firstUnAnsweredQuestion: firstUnAnsweredQuestion,
      };
    }
    case SEARCH_VENDOR_PRODUCT_INFORMATION_FAILURE: {
      return {
        ...state,
        loadingProductInformations: false,
        productInformations: [],
        productInformationStatusId: null,
      };
    }

    case SET_PRODUCT_INFORMATION_QUESTION_INDEX: {
      return {
        ...state,
        productInformationQuestionIndex: payload,
      };
    }

    case SET_PRODUCT_INFORMATION_QUESTION_LIST: {
      return {
        ...state,
        productInformationQuestionList: payload,
      }
    }


    case GET_PRODUCT_INFORMATION_IMAGE_URL_LOADING: {
      return {
        ...state,
        urlLoading: true,
      };
    }

    case GET_PRODUCT_INFORMATION_IMAGE_URL_SUCCESS: {
      const downloadUrl = payload?.Data?.DownloadUrl;
      const productInformationQuestion = state?.productInformationQuestionList?.find(item => item?.ProductPreferenceQuestionId === ProductPreferenceQuestionId);
      productInformationQuestion["QuestionImageUrl"] = downloadUrl;

      let tempData = state?.productInformationQuestionList?.map(item => {
        if (item?.ProductPreferenceQuestionId === ProductPreferenceQuestionId) {
          return productInformationQuestion;
        } else {
          return item;
        }
      });

      return {
        ...state,
        urlLoading: false,
        productInformationQuestionList: tempData,
      }
    }
    case GET_PRODUCT_INFORMATION_IMAGE_URL_FAILURE: {
      return {
        ...state,
        urlLoading: false,
      }
    }
    case SET_EXPANDED_PRODUCT_RECOMMENDATION_ID: {
      return {
        ...state,
        productRecommendationsExpandedId: isNil(payload) || payload === state.productRecommendationsExpandedId ? null : payload,
      };
    }
    case SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productDetailsRecommendations: payload,
      };
    }
    case SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productDetailsRecommendations: [],
      };
    }
    case SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_SUCCESS: {
      let tempData = [];
      if (!isEmpty(payload) && payload?.length > 0) {
        tempData = payload[0];
      }
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productDetailsRecommendationsTotal: tempData,
      };
    }
    case SEARCH_VENDOR_PRODUCT_DETAILS_RECOMMENDATIONS_TOTAL_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productDetailsRecommendationsTotal: [],
      };
    }
    case POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_SUCCESS: {
      toast.success(PRODUCT_RECOMMENDATIONS_UPDATED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case POST_VENDOR_PRODUCT_PREFERENCES_RECOMMENDATION_STATUS_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_SUCCESS: {
      let tempData = [];
      payload.forEach((item, index) => {
        let requirementDays = null;
        if (isNil(item?.CumulativeDueDays)) {
          requirementDays = NO_EXPIRATION;
        } else if (Math.sign(item?.CumulativeDueDays) === OVERVIEW_DAYS) {
          requirementDays = OVERDUE;
        } else if (Math.sign(item?.CumulativeDueDays) === TODAY_DUE_DAYS) {
          requirementDays = TODAY;
        } else {
          requirementDays = `IN ${item?.CumulativeDueDays} DAYS`
        }
        tempData.push({
          ...item,
          id: index,
          RequirementInDays: requirementDays
        });
      });
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productDetailsUpcomingMaintenance: tempData,
      };
    }
    case SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productDetailsUpcomingMaintenance: [],
      };
    }

    case SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_SUCCESS: {
      let tempData = [];
      payload.forEach((item, index) => {
        if (index > 2) return;
        let requirementDays = null;
        if (isNil(item?.CumulativeDueDays)) {
          requirementDays = NO_EXPIRATION;
        } else if (Math.sign(item?.CumulativeDueDays) === OVERVIEW_DAYS) {
          requirementDays = OVERDUE;
        } else if (Math.sign(item?.CumulativeDueDays) === TODAY_DUE_DAYS) {
          requirementDays = TODAY;
        } else {
          requirementDays = `IN ${item?.CumulativeDueDays} DAYS`
        }
        tempData.push({
          ...item,
          id: index,
          RequirementInDays: requirementDays
        });
      });
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productDetailsUpcomingMaintenanceOverview: tempData,
      };
    }
    case SEARCH_VENDOR_PRODUCT_DETAILS_UPCOMING_MAINTENANCE_OVERVIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
        productDetailsUpcomingMaintenanceOverview: [],
      };
    }

    case POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_LOADING: {
      return {
        ...state,
        loadingProductInformation: true,
      };
    }

    case POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_SUCCESS: {
      return {
        ...state,
        loadingProductInformation: false,
      };
    }

    case POST_VENDOR_PRODUCT_INFORMATION_RESPONSE_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loadingProductInformation: false,
      };
    }

    case SET_PRODUCT_INFO_REVIEW: {
      return {
        ...state,
        isProductInformationReview: payload,
      };
    }

    case SEARCH_VENDOR_PRODUCT_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SEARCH_VENDOR_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case SEARCH_VENDOR_PRODUCT_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case SET_OPEN_HELP_MODAL: {
      const { helpModalType } = action;
      return {
        ...state,
        loading: false,
        openHelpModal: payload,
        helpModalType: helpModalType,
      };
    }

    case POST_HELP_TICKET_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case POST_HELP_TICKET_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case POST_HELP_TICKET_SUCCESS: {
      toast.success(FEEDBACK_PROVIDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case SUBMIT_IPI_MANAGEMENT_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case SUBMIT_IPI_MANAGEMENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case SUBMIT_IPI_MANAGEMENT_SUCCESS: {
      toast.success(SUBMITTED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case ADD_CORL_CLEARED_ASSESSMENT_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }

    case ADD_CORL_CLEARED_ASSESSMENT_SUCCESS: {
      toast.success(CORL_CLEARED_ASSESSMENT_ADDED_SUCCESSFULLY);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case ADD_CORL_CLEARED_ASSESSMENT_FAILURE: {
      toast.error(ERROR_MESSAGE);
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case ADD_CORL_CLEARED_ASSESSMENT_REQUEST_LOADING: {
      return {
        ...state,
        loading: true,
        loadingCount: state.loadingCount + 1,
      };
    }
    case ADD_CORL_CLEARED_ASSESSMENT_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }
    case ADD_CORL_CLEARED_ASSESSMENT_REQUEST_FAILURE: {
      return {
        ...state,
        loading: false,
        loadingCount: state.loadingCount - 1,
      };
    }

    case SET_PRODUCT_PROFILE_WIZARD_TAB_VALUE: {
      return {
        ...state,
        productProfileWizardTabValue: payload,
      };
    }

    default:
      return state;
  }
}
